import {FC, lazy, Suspense} from 'react'
import {Navigate, Route, Routes} from 'react-router-dom'
import {MasterLayout} from '../../_metronic/layout/MasterLayout'
import TopBarProgress from 'react-topbar-progress-indicator'
import {PrivList} from '../pages/privilege/Priv/PrivList'
import {PrivGroupList} from '../pages/privilege/PrivGroup/PrivGroupList'
import {PrivGroupMore} from '../pages/privilege/PrivGroup/PrivGroupMore'



import {MenuTestPage} from '../pages/MenuTestPage'
import {getCSSVariableValue} from '../../_metronic/assets/ts/_utils'
import {WithChildren} from '../../_metronic/helpers'
// import { Template } from 'webpack'
import DashboardWrapper from '../pages/dashboard/DashboardWrapper'
import { TatlagaList } from '../pages/products/tatlaga'
import { CranList } from '../pages/products/cran'
import { SambarList } from '../pages/products/sambar'
import { OndriinList } from '../pages/products/ondriin'
import { TatlagaMore } from '../pages/products/tatlaga/more'
import { Employee } from '../pages/employee'
import { EmployeeMore } from '../pages/employee/more'
import { CranMore } from '../pages/products/cran/more'
import { SambarMore } from '../pages/products/sambar/more'
import { OndriinMore } from '../pages/products/ondriin/more'
import { OrderLists } from '../pages/order/orderlist'
import { CranOrderLists } from '../pages/order/cranOrderList'
import { OrderMore } from '../pages/order/more'
import { CustomerLists } from '../pages/customer/customerList'
import { CustomerMore } from '../pages/customer/more'
import { SambarPriceList } from '../pages/products/sambar_price'
const PrivateRoutes = () => {
  const ProfilePage = lazy(() => import('../modules/profile/ProfilePage'))
  const WizardsPage = lazy(() => import('../modules/wizards/WizardsPage'))
  const AccountPage = lazy(() => import('../modules/accounts/AccountPage'))
  const WidgetsPage = lazy(() => import('../modules/widgets/WidgetsPage'))
  const ChatPage = lazy(() => import('../modules/apps/chat/ChatPage'))
  const UsersPage = lazy(() => import('../modules/apps/user-management/UsersPage'))

  return (
    <Routes>
      <Route element={<MasterLayout />}>
        {/* Redirect to Dashboard after success login/registartion */}
        <Route path='auth/*' element={<Navigate to='/dashboard' />} />
        {/* Pages */}
        <Route path='/privilege/privlist' element={<PrivList />} />
        <Route path='/privilege/privgrouplist' element={<PrivGroupList />} />
        <Route path='/privilege/privgroup/:id' element={<PrivGroupMore />} />
        <Route path='/privilege/employeelist' element={<Employee />} />
        <Route path='/privilege/EmployeeDetail/:id' element={<EmployeeMore />} />
        <Route path='/dashboard' element={<DashboardWrapper />} />
        <Route path='menu-test' element={<MenuTestPage />} />
        <Route path='/product-tatlaga' element={<TatlagaList />} />
        <Route path='/product-cran' element={<CranList />} />
        <Route path='/product-sambar' element={<SambarList />} />
        <Route path='/sambar-price' element={<SambarPriceList/>} />
        <Route path='/product-ondriin' element={<OndriinList />} />
        <Route path='/product-tatlaga/:id' element={<TatlagaMore />} />
        <Route path='/product-cran/:id' element={<CranMore />} />
        <Route path='/product-sambar/:id' element={<SambarMore />} />
        <Route path='/product-ondriin/:id' element={<OndriinMore />} />
        <Route path='/orders/all' element={<OrderLists />} />
        <Route path='/order/:id' element={<OrderMore />} />
        <Route path='/orders/:id' element={<OrderLists id={"dashboard"}/>} />
        <Route path='/cranorders/all' element={<CranOrderLists />} />
        <Route path='/customer' element={<CustomerLists />} />
        <Route path='/customer/:id' element={<CustomerMore />} />
        

        {/* Lazy Modules */}
        <Route
          path='crafted/pages/profile/*'
          element={
            <SuspensedView>
              <ProfilePage />
            </SuspensedView>
          }
        />
        <Route
          path='crafted/pages/wizards/*'
          element={
            <SuspensedView>
              <WizardsPage />
            </SuspensedView>
          }
        />
        <Route
          path='crafted/widgets/*'
          element={
            <SuspensedView>
              <WidgetsPage />
            </SuspensedView>
          }
        />
        <Route
          path='crafted/account/*'
          element={
            <SuspensedView>
              <AccountPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/chat/*'
          element={
            <SuspensedView>
              <ChatPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/user-management/*'
          element={
            <SuspensedView>
              <UsersPage />
            </SuspensedView>
          }
        />
        {/* Page Not Found */}
        <Route path='*' element={<Navigate to='/error/404' />} />
      </Route>
    </Routes>
  )
}

const SuspensedView: FC<WithChildren> = ({children}) => {
  const baseColor = getCSSVariableValue('--bs-primary')
  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  })
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
}

export {PrivateRoutes}
