import React, {Component, useEffect, useState} from 'react'
import {withTranslation} from 'react-i18next'
import {PageLink, PageTitle} from '../../../_metronic/layout/core'

import {setPageTitle, DashboardToonMedeelel, DashboardByStatus} from '../../../services/main'
import {DashboardToonMedeelelReq, magicnum, DashboardByStatusReq} from '../../../services/Request'
import {Card, CardHeader, CardBody, Row, Col, Table, Spinner, Input, FormGroup} from 'reactstrap'
import {isNullOrEmpty} from '../../../services/Utils'
import {Link} from 'react-router-dom'
import {useIntl} from 'react-intl'
import {t} from 'i18next'

const dashboardBreadCrumbs: Array<PageLink> = [
  {
    title: 'Home',
    path: '/dashboard',
    isSeparator: false,
    isActive: false,
  },
]

const DashboardWrapper = () => {
  const intl = useIntl()
  const currentYear = new Date().getFullYear()
  const [loading, setLoading] = useState(false)
  const [loadingStatus, setLoadingStatus] = useState(false)
  const [toonData, setToonData] = useState<any>([])
  const [byStatusData, setbyStatusData] = useState([])
  const [year, setYear] = useState('')
  const [mounth, setMounth] = useState('')
  const [years, setYears] = useState<any>([])

  useEffect(() => {
    const yearList = []
    for (let i = 2021; i <= currentYear; i += 1) {
      yearList.push(i)
    }
    setYears(yearList)
    getToonMedee()
    getByStatus()
  }, [])

  const getToonMedee = () => {
    if (!loading) {
      setLoading(true)
    }
    var req = DashboardToonMedeelelReq
    // req.filter.service_id = service_id;
    req.filter.year = year
    req.filter.mounth = mounth
    DashboardToonMedeelel(req, onTopSuccess, onFailed, 'POST')
  }

  const onTopSuccess = (response: any) => {
    setLoading(false)
    setToonData(response)
  }

  const onFailed = (error: any) => {
    setLoading(false)
    console.log(error)
  }

  const getByStatus = () => {
    if (!loadingStatus) {
      setLoadingStatus(true)
    }
    var req = DashboardByStatusReq
    // req.filter.service_id = this.state.service_id;
    req.filter.year = year
    req.filter.mounth = mounth
    DashboardByStatus(req, onByStatusSuccess, onFailed, 'POST')
  }

  const onByStatusSuccess = (response: any) => {
    setLoadingStatus(false)
    setbyStatusData(response.data)
  }

  return (
    <>
      <PageTitle breadcrumbs={dashboardBreadCrumbs}>
        {intl.formatMessage({id: 'MENU.DASHBOARD'})}
      </PageTitle>
      <div className='flex flex-col'>
      <h3 className='mb-4'>Бараа</h3>
        <div className='flex flex-wrap gap-3'>
          <Link to={"/product-sambar"} style={{width: '49%', height:"70px", fontWeight:600, color:"black"}} className='card flex-row items-center sambar toondata px-5 justify-between'>
            <span>ХАБЭА-Н САМБАР, ТЭМДЭГ ХЭРЭГСЭЛ</span>
            <span style={{fontSize:"16px"}}>{toonData.sambar}</span>
          </Link>
          <Link to={"/product-tatlaga"} style={{width: '49%', height:"70px", fontWeight:600, color:"black"}} className='card flex-row items-center sambar toondata px-5 justify-between'>
            <span>ТЭНЖЭЭ, АЧАА ООСОРЛОХ ХЭРЭГСЭЛ</span>
            <span style={{fontSize:"16px"}}>{toonData.tatlaga}</span>
          </Link>
          <Link to={"/product-cran"} style={{width: '49%', height:"70px", fontWeight:600, color:"black"}} className='card flex-row items-center sambar toondata px-5 justify-between'>
            <span>КРАН, ӨРГӨХ ХЭРЭГСЭЛ</span>
            <span style={{fontSize:"16px"}}>{toonData.cran}</span>
          </Link>
          <Link to={"/product-ondriin"} style={{width: '49%', height:"70px", fontWeight:600, color:"black"}} className='card flex-row items-center sambar toondata px-5 justify-between'>
            <span>ӨНДИЙН ХАМГААЛАХ ХЭРЭГСЭЛ</span>
            <span style={{fontSize:"16px"}}>{toonData.ondor}</span>
          </Link>
        </div>
        {/* <div className='flex gap-4'>
            <div className='flex flex-col gap-2'>
              <label>Жил</label>
              <select className='w-200px py-2' name='' id=''>
                {magicnum.Mounth.map((item, index) => {
                  return (
                    <option value={item} key={index}>
                      {item}
                    </option>
                  )
                })}
              </select>
            </div>
            <div className='flex flex-col gap-2'>
              <label>Сар</label>
              <select className='w-200px py-2' name='' id=''>
                {magicnum.Mounth.map((item, index) => {
                  return (
                    <option value={item} key={index}>
                      {item}
                    </option>
                  )
                })}
              </select>
            </div>
          </div> */}
        <div className='mt-8'>
          <h3 className='mb-4'>Захиалга</h3>
          <div className='flex flex-wrap gap-5'>
            {byStatusData.map((item: any, index: any) => {
              var cl = 'btn-success'
              if (item.status == 1) {
                cl = 'btn-secondary'
              } else if (item.status == 2) {
                cl = 'btn-danger'
              } else if (item.status == 3) {
                cl = 'btn-info'
              } else if (item.status == 4) {
                cl = 'btn-warning'
              } else if (item.status == 5) {
                cl = 'btn-success'
              }
              return (
                <div
                  className='card'
                  style={{
                    width: '49%',
                    minWidth: '49%',
                    display: 'flex',
                    flexDirection: 'row',
                    height: '60px',
                    overflow: 'hidden',
                    alignItems: 'center',
                    gap: '12px',
                  }}
                  key={index}
                >
                  <div
                    className={`${cl}`}
                    style={{
                      width: '10px',
                      height: '60px',
                      float: 'left',
                      marginRight: '10px',
                    }}
                  />
                  <div style={{width: '100%', paddingRight:"40px", fontWeight:"500"}} className='flex justify-between '>
                    <Link to={`/orders/${item.status}`}>
                      <span style={{fontSize:"14px", color:"black"}}>{t(`common:magicnum.OrderStatus.${item.status}`)}</span>
                    </Link>
                    <span style={{fontSize: '16px'}}>{item.too}</span>
                  </div>
                </div>
              )
            })}
          </div>
        </div>
      </div>
    </>
  )
}

export default DashboardWrapper
