import {FC, useEffect, useState} from 'react'
import {KTIcon} from '../../../../_metronic/helpers'
import {PageLink, PageTitle} from '../../../../_metronic/layout/core'
import {isNullOrEmpty, moneyFormat} from '../../../../services/Utils'
import CustomModal from '../../../modules/CustomModal/modal'
import {toast} from 'react-toastify'
import {confirmAlert} from 'react-confirm-alert'
import 'react-confirm-alert/src/react-confirm-alert.css'
import {useTranslation} from 'react-i18next'
import DataTable from '../../../modules/CustomBootsrapTable/CustomBootsrapTable'
import {
  BaseRequest,
  magicnum,
  ProductChangeCoverReq,
  ProductCranUpdateuldegdelReq,
  ProductOndorListReq,
  ProductOndorUpdateTolovReq,
  ProductOndorUpdateuldegdelReq,
} from '../../../../services/Request'
import {
  CDN_URL,
  ProductOndorChangeImg,
  ProductOndorDelete,
  ProductOndorList,
  ProductOndorUpdateTolov,
  ProductOndorUpdateuldegdel,
} from '../../../../services/main'
import {OndriinEdit} from './edit'

const dashboardBreadCrumbs: Array<PageLink> = [
  {
    title: 'Home',
    path: '/dashboard',
    isSeparator: false,
    isActive: false,
  },
]
type Props = {
  type?: string
  uid?: any
}

const OndriinList: FC<Props> = (props) => {
  const {t} = useTranslation()
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState<any>([])
  const [item, setItem] = useState<any>(undefined)
  const [PID, setPID] = useState(1)
  const [PTotal, setPTotal] = useState(1)
  const [sizePerPage, setSizePerPage] = useState(10)
  const [showModal, updateShowModal] = useState(false)
  const [addShowModal, setAddShowModal] = useState(false)
  const [statusModal, setStatusModal] = useState(false)
  const [showFilter, setShowFilter] = useState(false)
  const [coverImg, setCoverImg] = useState(false)
  const [name, setName] = useState('')
  const [status, setStatus] = useState<any>('')
  const [torol, setTorol] = useState('')
  const [code, setCode] = useState('')
  const [brand, setBrand] = useState('')
  const [newImg, setNewImg] = useState<any>('')
  const [newImgBinary, setNewImgBinary] = useState<any>('')
  const [changeUld, setChangeUld] = useState(false)
  const [uldegdel, setUldegdel] = useState<any>('')

  useEffect(() => {
    list()
  }, [PID])

  useEffect(() => {
    setUldegdel(item?.uldegdel)
  }, [item])

  useEffect(() => {
    if (!isNullOrEmpty(item)) {
      setStatus(item?.tolov)
    }
  }, [item])

  const list = () => {
    if (!loading) {
      setLoading(true)
    }
    var req = ProductOndorListReq
    req.pid = PID
    req.pSize = sizePerPage
    req.filter.name = name
    req.filter.torol = torol
    req.filter.code = code
    req.filter.brand = brand
    ProductOndorList(req, onSuccess, onFailed, 'POST')
  }
  const onSuccess = (response: any) => {
    setLoading(false)
    setData(response.data)
    setPTotal(response.pagination.ptotal)
  }
  const onFailed = (error: string) => {
    setLoading(false)
    return toast.error(error)
  }
  const onChangePage = (pid: any) => {
    if (pid >= 1) {
      setPID(pid)
      setLoading(true)
    }
  }
  const defaultRowFormat = (cell: any, row: any) => {
    return <span className='flex justify-center'>{cell}</span>
  }
  const priceRowFormat = (cell: any, row: any) => {
    return <span className='flex justify-center'>{moneyFormat(cell, 'MNT')}</span>
  }

  const torolRowFormat = (cell: any, row: any) => {
    return (
      <span className='flex justify-center'>
        {t(`common:magicnum.ProductOndriinTorol.${cell}`)}
      </span>
    )
  }
  const brandRowFormat = (cell: any, row: any) => {
    return <span className='flex justify-center'>{t(`common:magicnum.ProductBrand.${cell}`)}</span>
  }
  const imgRowFormat = (cell: any, row: any) => {
    var newName = cell.split('.')

    return (
      <div className='flex flex-col items-center'>
        <img
          src={CDN_URL + newName[1] + '-thumb.' + newName[2]}
          height={40}
          width={40}
          style={{objectFit: 'cover'}}
          // onError={(e) => (e.target.src = require("../../img/no-image.png"))}
        />
      </div>
    )
  }
  const linkRowFormat = (cell: any, row: any) => {
    return (
      <div className='flex flex-col gap-2'>
        <a href={'product-ondriin/' + row.id}>{cell}</a>
      </div>
    )
  }
  const statusRowFormat = (cell: any, row: any) => {
    var cl = 'btn-success'
    if (cell == 1) {
      cl = 'btn-success'
    } else if (cell == 2) {
      cl = 'btn-info'
    } else if (cell == 3) {
      cl = 'btn-warning'
    } else if (cell == 4) {
      cl = 'btn-danger'
    }

    let edit = (
      <div style={{textAlign: 'center'}}>
        <span
          style={{
            padding: '3px 10px',
            fontSize: 10,
            borderRadius: '5px',
            cursor: 'pointer',
          }}
          className={`${cl}`}
          onClick={() => {
            setStatusModal(true)
            setItem(row)
          }}
        >
          {t(`common:magicnum.ProductTatlagaTolov.${cell}`)}
        </span>
      </div>
    )
    return edit
  }
  
  const actionRowFormat = (cell: string, row: any) => {
    return (
      <div className='justify-center flex' style={{minWidth: '229px'}}>
        <div
          title='Барааны зураг солих'
          className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
          onClick={() => {
            setCoverImg(true)
            setItem(row)
          }}
        >
          <KTIcon iconName='picture' className='fs-3' />
        </div>
        <div
          className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
          onClick={() => {
            setItem(row)
            updateShowModal(true)
          }}
        >
          <KTIcon iconName='pencil' className='fs-3' />
        </div>
        <div
          className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
          onClick={() => submit(row, 'deleteMain')}
        >
          <KTIcon iconName='trash' className='fs-3' />
        </div>
      </div>
    )
  }
  const submit = (row: any, type: string) => {
    confirmAlert({
      customUI: ({onClose}) => {
        return (
          <div className='custom-confirm-ui'>
            <h1>{row.title}</h1>
            <p>
              {' '}
              {type == 'deleteMain' ? t('common:confirmDelete') : t('common:confirmChangeStatus')}
            </p>

            <div className='buttons'>
              <button className='btn btn-active-light btn-color-muted' onClick={onClose}>
                {t('common:no')}
              </button>
              <button
                className='btn btn-primary align-self-center'
                onClick={() => {
                  deleteFunc(row)
                  onClose()
                }}
              >
                {t('common:yes')}
              </button>
            </div>
          </div>
        )
      },
    })
  }
  const changeStatus = () => {
    var req = ProductOndorUpdateTolovReq
    req.ondor.tolov = status
    req.ondor.id = item?.id as string
    ProductOndorUpdateTolov(req, onUpdateStatusSuccess, onFailed, 'PUT')
  }
  const changeCover = () => {
    var formdata = new FormData()
    formdata.append('image', newImgBinary)
    var ondor = {
      id: item?.id,
      old_image: item?.image_link,
    }
    formdata.append('ondor', JSON.stringify(ondor))
    ProductOndorChangeImg(formdata, onUpdateStatusSuccess, onFailed, 'POST', 'file')
  }
  const uldegdelRowFormat = (cell: any, row: any) => {
    return (
      <div
        onClick={() => {
          setChangeUld(true)
          setItem(row)
        }}
        style={{margin:"auto", width:"fit-content"}}
      >
        <p
          style={{
            width: '40px',
            height:"28px",
            display:"flex",
            alignItems:"center",
            cursor: 'pointer',
            textAlign: 'center',
            justifyContent:"center",
            color:`${cell <= '0' ? 'black' : 'white'}`,
            backgroundColor: `${cell <= '0' ? '#f0932b' : 'green'}`,
            fontWeight:"500",
            borderRadius:"6px",
            marginBottom:"0"
          }}
        >
          {cell}
        </p>
      </div>
    )
  }
  const saveUld = () => {
    var req = ProductOndorUpdateuldegdelReq
    req.ondor.id = item?.id
    req.ondor.uldegdel = uldegdel
    ProductOndorUpdateuldegdel(req, onUpdateUldSuccess, onFailed, 'PUT')
  }

  const onUpdateUldSuccess = () => {
    list()
    setChangeUld(false)
    return toast.success(t('common:successUpdate'))
  }
  const onUpdateStatusSuccess = () => {
    setStatusModal(false)
    setCoverImg(false)
    list()
    return toast.success(t('common:successUpdate'))
  }
  const deleteFunc = (row: any) => {
    ProductOndorDelete(row.id, BaseRequest, onDeleteSuccess, onFailed, 'DELETE')
  }
  const onDeleteSuccess = () => {
    list()
    return toast.success(t('common:successDelete'))
  }
  const clearFilter = () => {
    setName('')
    if (PID > 1) {
      setPID(1)
    } else {
      list()
    }
  }
  const search = () => {
    if (PID > 1) {
      setPID(1)
    } else {
      list()
    }
  }
  var table = {
    sizePerPage: sizePerPage,
    headerKeyColumn: {
      title: 'id',
      dataField: 'PrivNo',
      dataSort: true,
      dataFormat: defaultRowFormat,
      hidden: true,
      filter: {
        type: 'TextFilter',
        placeholder: '...',
      },
    },
    headerOtherColumn: [
      {
        title: 'Барааны зураг',
        dataField: 'image_link',
        dataSort: false,
        dataFormat: imgRowFormat,
        hidden: false,
        filter: {
          type: 'TextFilter',
          placeholder: '...',
        },
      },
      {
        title: 'Барааны нэр',
        dataField: 'name',
        dataSort: false,
        dataFormat: linkRowFormat,
        hidden: false,
        filter: {
          type: 'TextFilter',
          placeholder: '...',
        },
      },
      {
        title: 'Код',
        dataField: 'code',
        dataSort: false,
        dataFormat: defaultRowFormat,
        hidden: false,
        filter: {
          type: 'TextFilter',
          placeholder: '...',
        },
      },
      {
        title: 'Үнэ',
        dataField: 'price',
        dataSort: false,
        dataFormat: priceRowFormat,
        hidden: false,
        filter: {
          type: 'TextFilter',
          placeholder: '...',
        },
      },
      {
        title: 'Брэнд',
        dataField: 'brand',
        dataSort: false,
        dataFormat: brandRowFormat,
        hidden: false,
        filter: {
          type: 'TextFilter',
          placeholder: '...',
        },
      },
      {
        title: 'Үлдэгдэл',
        dataField: 'uldegdel',
        dataSort: false,
        dataFormat: uldegdelRowFormat,
        hidden: false,
        filter: {
          type: 'TextFilter',
          placeholder: '...',
        },
      },
      {
        title: 'Төрөл',
        dataField: 'torol',
        dataSort: false,
        dataFormat: torolRowFormat,
        hidden: false,
        filter: {
          type: 'TextFilter',
          placeholder: '...',
        },
      },

      {
        title: 'Төлөв',
        dataField: 'tolov',
        dataSort: false,
        dataFormat: statusRowFormat,
        hidden: false,
        filter: {
          type: 'TextFilter',
          placeholder: '...',
        },
      },
      {
        title: '',
        dataField: 'id',
        dataSort: false,
        dataFormat: actionRowFormat,
        hidden: false,
        filter: {
          type: 'TextFilter',
          placeholder: '...',
        },
      },
    ],
  }
  const closeModal = () => {
    updateShowModal(false)
    setAddShowModal(false)
    setStatusModal(false)
    list()
  }
  const handleImageUpload = (event: any) => {
    const file = event.target.files[0]
    if (file) {
      const reader = new FileReader()
      reader.onload = () => {
        setNewImg(reader.result) // Get the image data URL
      }
      reader.readAsDataURL(file) // Read the file as a data URL
    }
  }
  return (
    <>
      {showModal ? (
        <CustomModal
          visible={showModal}
          closeModal={() => updateShowModal(false)}
          className='VideoModal'
        >
          <OndriinEdit t={t} item={item} type='edit' onAddSuccess={closeModal} />
        </CustomModal>
      ) : null}

      {addShowModal ? (
        <CustomModal
          visible={addShowModal}
          closeModal={() => setAddShowModal(false)}
          className='VideoModal'
        >
          <OndriinEdit t={t} item={item} type='add' onAddSuccess={closeModal} />
        </CustomModal>
      ) : null}

      {statusModal ? (
        <CustomModal
          visible={statusModal}
          closeModal={() => setStatusModal(false)}
          className='VideoModal'
        >
          <div style={{minWidth: '400px', padding: '40px 30px'}} className='flex flex-col gap-6'>
            <h3 style={{margin: 'auto', marginBottom: '12px'}}>Барааны төлөв солих</h3>
            <select
              className='form-select form-select form-select-solid'
              value={status}
              onChange={(e) => setStatus(e.target.value)}
              name=''
              id=''
            >
              {magicnum.ProductTatlagaTolov.map((el) => {
                return <option value={el}>{t(`common:magicnum.ProductTatlagaTolov.${el}`)}</option>
              })}
            </select>
            <div style={{margin: 'auto'}} className='buttons'>
              <button className='btn btn-primary align-self-center' onClick={changeStatus}>
                Хадгалах
              </button>
            </div>
          </div>
        </CustomModal>
      ) : null}

      {coverImg ? (
        <CustomModal
          visible={coverImg}
          closeModal={() => setCoverImg(false)}
          className='VideoModal'
        >
          <div style={{minWidth: '500px', padding: '40px 30px'}} className='flex flex-col gap-6'>
            <h3 style={{margin: 'auto', marginBottom: '12px'}}>Барааны зураг солих</h3>
            {newImg ? null : (
              <div style={{display: 'flex', alignItems: 'center', gap: '16px'}}>
                <span>Шинэ зураг оруулах:</span>
                <input
                  type='file'
                  onChange={(e) => {
                    handleImageUpload(e)
                    setNewImgBinary(e.target.files?.[0])
                  }}
                />
              </div>
            )}

            <div className='flex items-center gap-5'>
              <div style={{width: '200px', height: '200px', objectFit: 'contain', margin: 'auto'}}>
                <img
                  width={200}
                  height={200}
                  style={{objectFit: 'contain'}}
                  src={CDN_URL + item?.image_link.slice(1)}
                  alt=''
                />
              </div>
              {newImg && (
                <div style={{height: '200px', display: 'flex', alignItems: 'center'}}>
                  <i style={{fontSize: '32px'}} className='fa-solid fa-arrow-right'></i>
                </div>
              )}
              {newImg && (
                <div
                  style={{
                    width: '200px',
                    height: '200px',
                    objectFit: 'contain',
                    margin: 'auto',
                    position: 'relative',
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <img
                    src={newImg}
                    alt='Uploaded Preview'
                    style={{maxWidth: '100%', height: 'auto'}}
                  />
                  <i
                    onClick={() => setNewImg('')}
                    style={{
                      position: 'absolute',
                      fontSize: '24px',
                      top: '-10px',
                      right: '-10px',
                      color: 'red',
                      cursor: 'pointer',
                    }}
                    className='fa-solid fa-xmark'
                  ></i>
                </div>
              )}
            </div>

            <div style={{margin: 'auto'}} className='buttons'>
              <button className='btn btn-primary align-self-center' onClick={changeCover}>
                Хадгалах
              </button>
            </div>
          </div>
        </CustomModal>
      ) : null}

      {changeUld ? (
        <CustomModal
          visible={changeUld}
          closeModal={() => setChangeUld(false)}
          className='VideoModal'
        >
          <div style={{minWidth: '500px', padding: '40px 30px'}} className='flex flex-col gap-6'>
            <h3 style={{margin: 'auto', marginBottom: '12px'}}>Барааны үлдэгдэл</h3>

            <div style={{margin: 'auto'}} className='flex gap-2'>
              <div
                onClick={() => setUldegdel(parseInt(uldegdel) - 1)}
                className='greyBtn'
              >
                -
              </div>
              <input
                className='form-control form-control-solid w-100px'
                placeholder='Үлдэгдэл'
                onChange={(e) => {
                  setUldegdel(e.target.value)
                }}
                value={uldegdel}
              ></input>
              <div
                onClick={() => setUldegdel(parseInt(uldegdel) + 1)}
                className='greyBtn'
              >
                +
              </div>
              <button className='btn btn-primary align-self-center' onClick={saveUld}>
                Хадгалах
              </button>
            </div>
          </div>
        </CustomModal>
      ) : null}

      {props.type == 'component' ? null : (
        <>
          <PageTitle breadcrumbs={dashboardBreadCrumbs}>Өндрийн</PageTitle>

          <div className={`card mb-5`}>
            <div className='card-header border-1' style={{height: '60px'}}>
              <div
                onClick={() => setShowFilter(!showFilter)}
                className=' cursor-pointer filter flex items-center justify-center gap-0'
              >
                <KTIcon iconName='filter' className='fs-3' />
                <span className='text'>{t('common:filter')}</span>
              </div>
              <div className='card-toolbar'>
                <a onClick={() => search()} className='btn btn-sm btn-light-primary'>
                  {t('common:search')}
                </a>
              </div>
            </div>
            {showFilter ? (
              <div className='card-body'>
                <div className='row flex items-center filter-wrap'>
                  <div className='col'>
                    <input
                      className='form-control form-control-sm form-control-solid'
                      id='name'
                      type='text'
                      onChange={(e) => setName(e.target.value)}
                      placeholder='Барааны нэр'
                      value={name}
                    />
                  </div>
                  <div className='col'>
                    <input
                      className='form-control form-control-sm form-control-solid'
                      id='name'
                      type='text'
                      onChange={(e) => setCode(e.target.value)}
                      placeholder='Код'
                      value={code}
                    />
                  </div>
                  <div className='col'>
                    <select
                      onChange={(e) => {
                        setTorol(e.target.value)
                      }}
                      className='form-select form-select-sm form-select-solid'
                      name='category'
                      value={torol}
                    >
                      <option value=''>{t('common:select')}</option>
                      {magicnum.ProductOndriinTorol.map((pitem) => {
                        return (
                          <option value={pitem}>
                            {t(`common:magicnum.ProductOndriinTorol.${pitem}`)}
                          </option>
                        )
                      })}
                    </select>
                  </div>
                  <div className='col'>
                    <select
                      onChange={(e) => {
                        setBrand(e.target.value)
                      }}
                      className='form-select form-select-sm form-select-solid'
                      value={brand}
                    >
                      <option value=''>{t('common:select')}</option>
                      {magicnum.ProductBrand.map((item, index) => {
                        return (
                          <option value={item} key={index}>
                            {t(`common:magicnum.ProductBrand.${item}`)}
                          </option>
                        )
                      })}
                    </select>
                  </div>
                  <div className='col'>
                    <a
                      onClick={() => clearFilter()}
                      className='btn btn-sm btn-icon btn-color-primary btn-active-light-primary'
                    >
                      {t('common:USERS.clear')}
                    </a>
                  </div>
                </div>
              </div>
            ) : null}
          </div>
        </>
      )}

      <div className={`card`}>
        <div className='card-header border-0 pt-5'>
          <h3 className='card-title align-items-start flex-column'>
            <span className='card-label fw-bold fs-3 mb-1'>Өндрийн хамгаалах хэрэгсэл</span>
            <span className='text-muted mt-1 fw-semibold fs-7'>Total {PTotal} бараа</span>
          </h3>
          <div
            className='card-toolbar'
            data-bs-toggle='tooltip'
            data-bs-placement='top'
            data-bs-trigger='hover'
            title='Click to add a user'
          >
            <div
              className='btn btn-sm btn-light-primary'
              onClick={() => {
                setItem(undefined)
                setAddShowModal(true)
              }}
            >
              <KTIcon iconName='plus' className='fs-3' />
              Бараа нэмэх
            </div>
          </div>
        </div>
        <div className='card-body py-3'>
          <DataTable
            data={data}
            table={table}
            PTotal={PTotal}
            pageChange={onChangePage}
            PID={PID}
            loading={loading}
          />
        </div>
      </div>
    </>
  )
}

export {OndriinList}
