import React, {FC, useState} from 'react'
import {isNullOrEmpty} from '../../../services/Utils'
import {toast} from 'react-toastify'
import {CustomerAdd, CustomerUpdate, EmployeeAdd} from '../../../services/main'
import {CustomerAddReq, CustomerUpdateReq, magicnum} from '../../../services/Request'

interface IProps {
  t: any
  item: any
  onAddSuccess: any
  type: any
}
const CustomerAddModal: FC<IProps> = ({t, item, onAddSuccess, type}) => {
  const [rd, setRd] = useState(isNullOrEmpty(item) ? '' : item.rd)
  const [name, setName] = useState(isNullOrEmpty(item) ? '' : item.name)
  const [email, setEmail] = useState<any>(isNullOrEmpty(item) ? '' : item.email)
  const [mobile, setMobile] = useState(isNullOrEmpty(item) ? '' : item.mobile)
  const [tele, setTele] = useState(isNullOrEmpty(item) ? '' : item.telephone)
  const [person, setperson] = useState(isNullOrEmpty(item) ? 'super' : item.holbogdoh_hun)
  const [status, setStatus] = useState(isNullOrEmpty(item) ? '' : item.status)
  const [work, setWork] = useState(isNullOrEmpty(item) ? '' : item.alban_tushaal)
  const [count, setCount] = useState(0)
  const [password, setPassword] = useState(isNullOrEmpty(item) ? '' : item.password)

  const [loading, setLaoding] = useState(false)
  const [validate, setValidate] = useState<any>([])

  const save = () => {
    var res = validateFunc()
    if (res.retType === 0) {
      setLaoding(true)
      var req = CustomerAddReq
      req.Customer.name = name
      req.Customer.rd = rd
      req.Customer.password = password
      req.Customer.email = email
      req.Customer.mobile = mobile
      req.Customer.telephone = tele
      req.Customer.holbogdoh_hun = person
      req.Customer.alban_tushaal = work
      req.Customer.status = status
      CustomerAdd(req, onSuccess, onFailed, 'POST')
    }
  }

  const onSuccess = () => {
    setLaoding(false)
    onAddSuccess(t('common:successCreate'))
  }

  const onFailed = (err: string) => {
    setLaoding(false)
    return toast.error(err)
  }

  const update = () => {
    var res = validateFunc()
    if (res.retType === 0) {
      setLaoding(true)
      var req = CustomerUpdateReq
      req.Customer.name = name
      req.Customer.rd = rd
      req.Customer.email = email
      req.Customer.mobile = mobile
      req.Customer.telephone = tele
      req.Customer.holbogdoh_hun = person
      req.Customer.alban_tushaal = work
      req.Customer.status = status
      req.Customer.id = item?.id
      CustomerUpdate(req, onSuccess, onFailed, 'PUT')
    }
  }

  const onUpdateSuccess = () => {
    setLaoding(false)
    onAddSuccess(t('common:successUpdate'))
  }

  const validateFunc = () => {
    var res = {retType: 0}

    if (isNullOrEmpty(rd)) {
      res.retType = 1
      validate['rd'] = true
    }

    if (isNullOrEmpty(name)) {
      res.retType = 1
      validate['name'] = true
    }

    if (isNullOrEmpty(password)) {
      res.retType = 1
      validate['password'] = true
    }

    if (isNullOrEmpty(email)) {
      res.retType = 1
      validate['email'] = true
    }

    if (isNullOrEmpty(mobile)) {
      res.retType = 1
      validate['mobile'] = true
    }

    if (isNullOrEmpty(tele)) {
      res.retType = 1
      validate['tele'] = true
    }

    if (isNullOrEmpty(status)) {
      res.retType = 1
      validate['status'] = true
    }

    if (isNullOrEmpty(person)) {
      res.retType = 1
      validate['person'] = true
    }
    if (isNullOrEmpty(work)) {
      res.retType = 1
      validate['work'] = true
    }
    setValidate(validate)
    setCount(count + 1)
    return res
  }

  const validateChange = (id: string, e: any) => {
    var val = ''
    e.target === undefined ? (val = e) : (val = e.target.value)
    if (val !== '') {
      validate[id] = false
    } else {
      validate[id] = true
    }
    setValidate(validate)
    setCount(count + 1)
  }

  return (
    <div>
      <div className='scroll-x mx-5 mx-xl-10 pt-10 pb-10 w-500px'>
        <span style={{visibility: 'hidden'}}>{count}</span>
        <div className='text-center mb-13'>
          <h1 className='mb-3'>
            {isNullOrEmpty(item) ? 'Хэрэглэгч нэмэх' : 'Хэрэглэгчийн мэдээлэл засах'}
          </h1>
        </div>

        <div className='separator mt-8 d-flex flex-center mb-8'>
          <span className='text-uppercase bg-body fs-7 fw-bold text-muted px-3'>text</span>
        </div>
        <div className='mb-8'>
          <input
            className='form-control form-control-solid'
            placeholder='Харилцагчийн РД'
            onChange={(e) => {
              setRd(e.target.value)
              validateChange('rd', e)
            }}
            value={rd}
            disabled={isNullOrEmpty(item) ? false : true}
          ></input>
          {validate['rd'] ? (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>Харилцагчын РД оруулна уу!</div>
            </div>
          ) : null}
        </div>
        <div className='mb-8'>
          <input
            className='form-control form-control-solid'
            placeholder='Харилцагчын нэр'
            onChange={(e) => {
              setName(e.target.value)
              validateChange('name', e)
            }}
            disabled={isNullOrEmpty(item) ? false : true}
            value={name}
          ></input>
          {validate['name'] ? (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>Харилцагчын нэр оруулна уу!</div>
            </div>
          ) : null}
        </div>

        {isNullOrEmpty(item) ? (
          <div className='mb-8'>
            <input
              className='form-control form-control-solid'
              placeholder='Нууц үг'
              onChange={(e) => {
                setPassword(e.target.value)
                validateChange('password', e)
              }}
              value={password}
            ></input>
            {validate['password'] ? (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>Нууц үг оруулна уу!</div>
              </div>
            ) : null}
          </div>
        ) : null}

        <div className='mb-8'>
          <input
            className='form-control form-control-solid'
            placeholder='email'
            onChange={(e) => {
              setEmail(e.target.value)
              validateChange('email', e)
            }}
            value={email}
          ></input>
          {validate['email'] ? (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>Email оруулна уу!</div>
            </div>
          ) : null}
        </div>

        <div className='mb-8'>
          <input
            className='form-control form-control-solid'
            placeholder='Гар утасны дугаар'
            onChange={(e) => {
              setMobile(e.target.value)
              validateChange('mobile', e)
            }}
            value={mobile}
          ></input>
          {validate['mobile'] ? (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>Гар утасны дугаар оруулна уу!</div>
            </div>
          ) : null}
        </div>

        <div className='mb-8'>
          <input
            className='form-control form-control-solid'
            placeholder='Ажилын утасны дугаар'
            onChange={(e) => {
              setTele(e.target.value)
              validateChange('tele', e)
            }}
            value={tele}
          ></input>
          {validate['tele'] ? (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>Ажилын утасны дугаар оруулна уу!</div>
            </div>
          ) : null}
        </div>

        <div className='mb-8'>
          <select
            name='status'
            data-control='select2'
            data-hide-search='true'
            value={status}
            className='form-select form-select-white form-select-sm'
            onChange={(e) => {
              setStatus(e.target.value)
              validateChange('status', e)
            }}
          >
            <option value=''>{t('common:employee.selectStatus')}</option>
            <option value={magicnum.Status.InActive}>{t('common:magicnum.Status.0')}</option>
            <option value={magicnum.Status.Active}>{t('common:magicnum.Status.1')}</option>
          </select>
          {validate['status'] ? (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>{t('common:validSelect')}</div>
            </div>
          ) : null}
        </div>

        <div className='mb-8'>
          <input
            className='form-control form-control-solid'
            placeholder=''
            onChange={(e) => {
              setperson(e.target.value)
              validateChange('person', e)
            }}
            value={person}
          ></input>
          {validate['person'] ? (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>Холбогдох хүний нэр оруулна уу!</div>
            </div>
          ) : null}
        </div>

        <div className='mb-8'>
          <input
            className='form-control form-control-solid'
            placeholder='Ажилын газар'
            onChange={(e) => {
              setWork(e.target.value)
              validateChange('work', e)
            }}
            value={work}
          ></input>
          {validate['work'] ? (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>{t('common:validInsert')}</div>
            </div>
          ) : null}
        </div>

        <div className='pt-10' style={{textAlign: 'right'}}>
          <button
            className='btn btn-primary align-self-center'
            onClick={isNullOrEmpty(item) ? save : update}
            disabled={loading}
          >
            {loading ? t('common:loading') : 'Save'}
          </button>
        </div>
      </div>
    </div>
  )
}

export {CustomerAddModal}
