// import {FC, useEffect, useState} from 'react'
// import {KTIcon} from '../../../_metronic/helpers'
// import {SizeUnits, dateFormat, encodeString, isNullOrEmpty} from '../../../services/Utils'
// import {BaseRequest} from '../../../services/Request'
// import {CDN_URL, FlipbookGetByID} from '../../../services/main'
// import {toast} from 'react-toastify'
// import 'react-confirm-alert/src/react-confirm-alert.css'
// import {useTranslation} from 'react-i18next'
// import {Link, useParams} from 'react-router-dom'
// import clsx from 'clsx'
// import {PageLink, PageTitle} from '../../../_metronic/layout/core'
// import MonthlyStat from './stats/time'
// import LocationStat from './stats/location'
// import ScanedDevices from './stats/device'
// import ScanedOs from './stats/os'

import {Link, useParams} from 'react-router-dom'
import {PageLink, PageTitle} from '../../../_metronic/layout/core'
import {useTranslation} from 'react-i18next'
import {FC, useEffect, useRef, useState} from 'react'
import {BaseRequest, CustomerCheckReq} from '../../../services/Request'
import {toast} from 'react-toastify'
import {KTIcon} from '../../../_metronic/helpers'
import {
  CDN_URL,
  CustomerCheck,
  OrderGet,
  ProductSambarGet,
  ProductTatlagaGet,
} from '../../../services/main'
import clsx from 'clsx'
import {dateFormat, getRealData, isNullOrEmpty, moneyFormat} from '../../../services/Utils'
import DataTable from '../../modules/CustomBootsrapTable/CustomBootsrapTable'
import ExportToExcel from './exportToExcel'
import {profile} from 'console'
import CustomModal from '../../modules/CustomModal/modal'
import {Table} from 'react-bootstrap'
import html2canvas from 'html2canvas'
import jsPDF from 'jspdf'
import {asString} from 'html2canvas/dist/types/css/types/color'

const dashboardBreadCrumbs: Array<PageLink> = [
  {
    title: 'Нүүр',
    path: '/dashboard',
    isSeparator: false,
    isActive: false,
  },
  {
    title: 'Захиалга',
    path: '/orders/all',
    isSeparator: false,
    isActive: false,
  },
]

const OrderMore: FC = () => {
  const {id} = useParams()
  const {t} = useTranslation()
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState<any>([])
  const [products, setProducts] = useState<any>([])
  const [tab, setTab] = useState('products')
  const [PID, setPID] = useState(1)
  const [PTotal, setPTotal] = useState(1)
  const [sizePerPage, setSizePerPage] = useState(10)
  const [nehemjlelModal, setNehemjlelModal] = useState(false)
  const [addedDate, setAddedDate] = useState('')
  const [nehdata, setNehdata] = useState([])
  const nehemjlel = useRef(null)
  const [loadingCheck, setLoadingCheck] = useState(false)
  const [name, setName] = useState('')
  const nehemlehDay = 5

  useEffect(() => {
    get()
  }, [])

  useEffect(() => {
    CheckRD()
    const result = new Date(data?.created_at)
    const final = new Date(result.setDate(result.getDate() + nehemlehDay))
    setAddedDate(formatDate(final))
  }, [data])

  const get = () => {
    if (!loading) {
      setLoading(true)
    }
    OrderGet(id, BaseRequest, onGetSuccess, onFailed, 'GET')
  }

  const onGetSuccess = (response: any) => {
    setLoading(false)
    setData(response.data)
    setProducts(response.products)
  }
  const onFailed = (error: string) => {
    setLoading(false)
    return toast.error(error)
  }
  const defaultRowFormat = (cell: any, row: any) => {
    return <span className='flex justify-center'>{cell}</span>
  }

  const codeRowFormat = (cell: any, row: any) => {
    return (
      <span className='flex justify-center'>
        {row?.product_type === 'tatlaga'
          ? row.tcode
          : row?.product_type === 'ondor'
          ? row.ocode
          : row.scode}
      </span>
    )
  }

  const nameRowFormat = (cell: any, row: any) => {
    return (
      <span className='flex justify-center'>
        {row?.product_type === 'tatlaga'
          ? row.tname
          : row?.product_type === 'ondor'
          ? row.oname
          : row.sname}
      </span>
    )
  }
  const songolt1RowFormat = (cell: any, row: any) => {
    return row.product_type == 'tatlaga' ? (
      <span style={{fontSize: 11}}>{t(`common:magicnum.ProductTatlagaJin.${cell}`)}</span>
    ) : row.product_type == 'ondor' ? (
      <span style={{fontSize: 11}}></span>
    ) : (
      <span style={{fontSize: 11}}>{t(`common:magicnum.ProductSambarHemjee.${cell}`)}</span>
    )
  }
  const songolt2RowFormat = (cell: any, row: any) => {
    return row.product_type === 'tatlaga' ? (
      <span style={{fontSize: 11}}>{t(`common:magicnum.ProductTatlagaUrt.${cell}`)}</span>
    ) : row.product_type === 'ondor' ? (
      <span style={{fontSize: 11}}></span>
    ) : (
      <span style={{fontSize: 11}}>{t(`common:magicnum.ProductSambarMaterial.${cell}`)}</span>
    )
  }
  const imgRowFormat = (cell: any, row: any) => {
    // var newName = row.product_type == 'tatlaga' ? row.timage.split('.') : row.simage.split('.')
    return (
      <div className='text-center'>
        {row.product_type == 'tatlaga' ? (
          isNullOrEmpty(row.timage) ? (
            'no image'
          ) : (
            <img
              height={40}
              width={40}
              style={{borderRadius: '50%', objectFit: 'cover'}}
              src={CDN_URL + row.timage?.slice(1)}
              alt=''
            />
          )
        ) : (
          ''
        )}
        {row.product_type == 'sambar' ? (
          isNullOrEmpty(row.simage) ? (
            'no image'
          ) : (
            <img
              height={40}
              width={40}
              style={{borderRadius: '50%', objectFit: 'cover'}}
              src={CDN_URL + row.simage?.slice(1)}
              alt=''
            />
          )
        ) : (
          ''
        )}
        {row.product_type == 'ondor' ? (
          isNullOrEmpty(row.oimage) ? (
            'no image'
          ) : (
            <img
              height={40}
              width={40}
              style={{borderRadius: '50%', objectFit: 'cover'}}
              src={CDN_URL + row.oimage?.slice(1)}
              alt=''
            />
          )
        ) : (
          ''
        )}
      </div>
    )
  }
  const priceRowFormat = (cell: any, row: any) => {
    return <span className='flex justify-center'>{moneyFormat(cell, 'MNT')}</span>
  }
  const totalpriceRowFormat = (cell: any, row: any) => {
    return <span className='flex justify-center'>{moneyFormat(cell * row.price, 'MNT')}</span>
  }
  const imgsRowFormat = (row: any) => {
    var newName =
      row.product_type == 'tatlaga'
        ? row.timage.split('.')
        : row.product_type == 'ondor'
        ? row.oimage.split('.')
        : row.simage.split('.')
    return (
      <div className='text-center'>
        <img
          src={CDN_URL + newName[1] + '-thumb.' + newName[2]}
          height={60}
          width={60}
          style={{objectFit: 'cover'}}
        />
      </div>
    )
  }
  const formatDate = (dateString: any) => {
    const date = new Date(dateString)
    const year = date.getFullYear()
    const month = String(date.getMonth() + 1).padStart(2, '0') // Months are 0-based
    const day = String(date.getDate()).padStart(2, '0')
    return `${year}.${month}.${day}`
  }
  const handleDownloadPdf = async (id: string, nehemjlel: React.RefObject<HTMLElement>) => {
    try {
      const element = nehemjlel.current
      if (!element) {
        throw new Error('No element found to render PDF.')
      }

      const images = element.querySelectorAll('img')
      const imagePromises = Array.from(images).map((img: HTMLImageElement) => {
        return new Promise<void>((resolve, reject) => {
          if (img.complete) {
            resolve()
          } else {
            img.onload = () => resolve()
            img.onerror = () => reject(new Error(`Failed to load image: ${img.src}`))
          }
        })
      })

      await Promise.all(imagePromises)

      const canvas = await html2canvas(element, {scale: 2, useCORS: true})
      const imgData = canvas.toDataURL('image/png')

      const pdf = new jsPDF('p', 'mm', 'a4')
      const pdfWidth = pdf.internal.pageSize.getWidth()
      const pdfHeight = (canvas.height * pdfWidth) / canvas.width

      pdf.addImage(imgData, 'PNG', 0, 0, pdfWidth, pdfHeight)
      pdf.save(`mkl-nehemjeh-${id}.pdf`)
    } catch (error) {
      console.error('Error creating PDF:', error)
    }
  }

  const onChangePage = (pid: any) => {
    if (pid >= 1) {
      setPID(pid)
      setLoading(true)
    }
  }
  var table = {
    sizePerPage: sizePerPage,
    headerKeyColumn: {
      title: 'id',
      dataField: 'PrivNo',
      dataSort: true,
      dataFormat: defaultRowFormat,
      hidden: true,
      filter: {
        type: 'TextFilter',
        placeholder: '...',
      },
    },
    headerOtherColumn: [
      {
        title: 'Барааны зураг',
        dataField: 'simage',
        dataSort: false,
        dataFormat: imgRowFormat,
        hidden: false,
        filter: {
          type: 'TextFilter',
          placeholder: '...',
        },
      },
      {
        title: 'Бараа',
        dataField: 'sname',
        dataSort: false,
        dataFormat: nameRowFormat,
        hidden: false,
        filter: {
          type: 'TextFilter',
          placeholder: '...',
        },
      },
      {
        title: 'Код',
        dataField: 'scode',
        dataSort: false,
        dataFormat: codeRowFormat,
        hidden: false,
        filter: {
          type: 'TextFilter',
          placeholder: '...',
        },
      },
      {
        title: 'Даац, хэмжээ',
        dataField: 'songolt1',
        dataSort: false,
        dataFormat: songolt1RowFormat,
        hidden: false,
        filter: {
          type: 'TextFilter',
          placeholder: '...',
        },
      },
      {
        title: 'Урт, материал',
        dataField: 'songolt2',
        dataSort: false,
        dataFormat: songolt2RowFormat,
        hidden: false,
        filter: {
          type: 'TextFilter',
          placeholder: '...',
        },
      },
      {
        title: 'Барааны төрөл',
        dataField: 'product_type',
        dataSort: false,
        dataFormat: defaultRowFormat,
        hidden: false,
        filter: {
          type: 'TextFilter',
          placeholder: '...',
        },
      },
      {
        title: 'Нэгж үнэ',
        dataField: 'price',
        dataSort: false,
        dataFormat: priceRowFormat,
        hidden: false,
        filter: {
          type: 'TextFilter',
          placeholder: '...',
        },
      },
      {
        title: 'Захиалсан тоо',
        dataField: 'too',
        dataSort: false,
        dataFormat: defaultRowFormat,
        hidden: false,
        filter: {
          type: 'TextFilter',
          placeholder: '...',
        },
      },
      {
        title: 'Нийт үнэ',
        dataField: 'too',
        dataSort: false,
        dataFormat: totalpriceRowFormat,
        hidden: false,
        filter: {
          type: 'TextFilter',
          placeholder: '...',
        },
      },
    ],
  }

  const CheckRD = () => {
    var req = CustomerCheckReq
    req.filter.rd = data?.rd
    setLoadingCheck(true)
    CustomerCheck(req, onCheckSuccess, onFailed, 'POST')
  }

  const onCheckSuccess = (response: any) => {
    setLoadingCheck(false)
    setName(response.data.name)
  }
  return (
    <>
      {nehemjlelModal ? (
        <CustomModal
          // name="card-modal"
          visible={nehemjlelModal}
          closeModal={() => setNehemjlelModal(false)}
          className=''
          // noClose={showModalType == "login" ? false : true}
        >
          <div style={{padding: '30px 0', maxWidth: '900px'}}>
            <div style={{position: 'absolute', top: '40px', right: '10px'}}>
              {data?.status == 6 ? (
                <div style={{border: 'green 4px solid', color: 'green'}} className='stamp'>
                  Төлөгдсөн
                </div>
              ) : (
                <div className='stamp'>Төлөгдөөгүй</div>
              )}
            </div>
            <div style={{padding: '48px 24px', position: 'relative'}} ref={nehemjlel}>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'baseline',
                }}
              >
                <h2
                  style={{
                    padding: '8px',
                    width: '350px',
                    backgroundColor: '#0f243e',
                    color: 'white',
                    fontSize: '24px',
                  }}
                >
                  МКЛ ХХК
                </h2>
                <span style={{fontSize: '24px', fontWeight: '600'}}>
                  Нэхэмжлэх №: W-{String(data?.id).padStart(3, '0')}
                </span>
              </div>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  marginTop: '36px',
                }}
              >
                <div style={{display: 'flex', flexDirection: 'column'}}>
                  <span>Утас: 75754449, 99077113, И-мэйл: info@mkl.mn</span>
                  <span>Регистрийн дугаар: 2893932</span>
                  <span>Банкны нэр: Голомт</span>
                  <span>Дансны дугаар: 1102808525 /төгрөгийн/</span>
                  <span>Захиалгын дугаар: W-{data?.id}</span>
                </div>
                <div style={{display: 'flex', flexDirection: 'column'}}>
                  <span style={{maxWidth: '300px'}}>Худалдан авагчийн нэр: {name}</span>
                  <span>Регистрийн дугаар: {data?.rd}</span>
                  <span>Нэхэмжилсэн огноо: {dateFormat(data.created_at)}</span>
                  <span>Төлбөр хийх хугацаа: {addedDate}</span>
                </div>
              </div>
              <div>
                <table className='table cart-table table-responsive-xs'>
                  <Table style={{marginTop: 40}}>
                    <thead>
                      <tr>
                        <th>№</th>
                        <th>Барааны зураг</th>
                        <th>Бараа</th>
                        <th>Даац хэмжээ</th>
                        <th>Урт материал</th>
                        <th>Нэгж үнэ</th>
                        <th>Захиалсан тоо</th>
                        <th>Нийт үнэ</th>
                      </tr>
                    </thead>
                    <tbody>
                      {products.map((uu: any, index: any) => {
                        return (
                          <tr key={index}>
                            <td>{index + 1}</td>
                            <td>{imgsRowFormat(uu)}</td>
                            <td>
                              {uu?.product_type == 'tatlaga'
                                ? uu.tname
                                : uu?.product_type == 'ondor'
                                ? uu.oname
                                : uu.sname}
                            </td>

                            <td>
                              {uu.product_type == 'tatlaga' ? (
                                <span style={{fontSize: 11}}>
                                  {t(`common:magicnum.ProductTatlagaJin.${uu.songolt1}`)}
                                </span>
                              ) : uu.product_type == 'ondor' ? (
                                <span style={{fontSize: 11}}></span>
                              ) : (
                                <span style={{fontSize: 11}}>
                                  {t(`common:magicnum.ProductSambarHemjee.${uu.songolt1}`)}
                                </span>
                              )}
                            </td>
                            <td>
                              {uu.product_type == 'tatlaga' ? (
                                <span style={{fontSize: 11}}>
                                  {t(`common:magicnum.ProductTatlagaUrt.${uu.songolt2}`)}
                                </span>
                              ) : uu.product_type == 'ondor' ? (
                                <span style={{fontSize: 11}}></span>
                              ) : (
                                <span style={{fontSize: 11}}>
                                  {t(`common:magicnum.ProductSambarMaterial.${uu.songolt2}`)}
                                </span>
                              )}
                            </td>

                            <td style={{textAlign: 'right'}}>{moneyFormat(uu.price, 'MNT')}</td>
                            <td>{uu.too}</td>
                            <td style={{textAlign: 'right'}}>
                              {moneyFormat(uu.price * uu.too, 'MNT')}
                            </td>
                          </tr>
                        )
                      })}
                    </tbody>
                  </Table>
                </table>
                <table className='table cart-table table-responsive-md mt-5'>
                  <tfoot>
                    <tr style={{borderTop: '1px solid black'}}>
                      <td style={{fontWeight: 'normal'}}>НИЙТ /НӨАТ-тай/</td>
                      <td>
                        <h2 style={{fontSize: 16, textAlign: 'end'}}>
                          {moneyFormat(data.total_price, 'MNT')}{' '}
                        </h2>
                      </td>
                    </tr>
                  </tfoot>
                </table>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-around',
                    marginTop: '80px',
                  }}
                >
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                      gap: '12px',
                    }}
                  >
                    <span>/Тамга/</span>
                    <img src='/img/sanhuu-tamga.png' alt='' />
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      gap: '8px',
                      fontSize: '14px',
                    }}
                  >
                    <span>Дарга: ..............................................</span>
                    <span>Нягтлан бодогч: .................................</span>
                  </div>
                </div>
              </div>
            </div>

            <div style={{position: 'absolute', top: '20px', left: '20px'}}>
              <button
                style={{
                  padding: '10px 20px',
                  borderRadius: '8px',
                  backgroundColor: '#3498db',
                  border: 'none',
                  color: 'white',
                  fontSize: '15px',
                }}
                onClick={() => handleDownloadPdf(id as string, nehemjlel)}
              >
                Download as PDF
              </button>
            </div>
          </div>
        </CustomModal>
      ) : null}
      <PageTitle breadcrumbs={dashboardBreadCrumbs}>{data?.id}</PageTitle>
      <div className={`card mb-5`}>
        <div style={{overflowX: 'auto'}} className='card-header border-1 w-full'>
          <div className='flex gap-8 col-form-label w-full card-header-flex'>
            <div className='flex flex-wrap gap-16 p-8 infos w-full'>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: '14px',
                  justifyContent: 'center',
                }}
              >
                <div>
                  <span style={{fontWeight: 'bold'}}>Захиалгын дугаар:</span>
                  <span> NO-{data?.id}</span>
                </div>
                <div>
                  <span style={{fontWeight: 'bold'}}>Хувиарласан ажилтан:</span>
                  <span> {t(data?.firstname)}</span>
                </div>
                <div>
                  <span style={{fontWeight: 'bold'}}>Төлөв:</span>
                  <span> {t(`common:magicnum.OrderStatus.${data?.status}`)}</span>
                </div>
                <div>
                  <span style={{fontWeight: 'bold'}}>Мөнгөн дүн:</span>
                  <span> {moneyFormat(data?.total_price, 'MNT')}</span>
                </div>
              </div>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: '14px',
                  justifyContent: 'center',
                }}
              >
                <div>
                  <span style={{fontWeight: 'bold'}}>Харилцагын РД:</span>
                  <span> {data?.rd}</span>
                </div>
                <div>
                  <span style={{fontWeight: 'bold'}}>Гар утасны дугаар:</span>
                  <span> {data?.mobile}</span>
                </div>
                <div>
                  <span style={{fontWeight: 'bold'}}>Ажлын утасны дугаар:</span>
                  <span>{data?.telephone}</span>
                </div>
                <div>
                  <span style={{fontWeight: 'bold'}}>Холбогдох хүн:</span>
                  <span>{data?.holbogdoh_hun}</span>
                </div>
              </div>
              <button
                onClick={() => setNehemjlelModal(true)}
                className='btn btn-sm btn-light-primary h-40px'
              >
                Нэхэмжлэл харах
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className='card'>
        <div style={{overflowX: 'auto'}} className='card-header card-header-stretch'>
          <ul
            className=' nav nav-stretch nav-line-tabs fw-bold border-transparent flex-nowrap'
            role='tablist'
          >
            <li className='nav-item'>
              <a
                className={clsx(`nav-link cursor-pointer`, {
                  active: tab === 'products',
                })}
                onClick={() => setTab('products')}
                role='tab'
              >
                Захиалсан бараанууд
              </a>
            </li>
            <li className='nav-item'>
              <a
                className={clsx(`nav-link cursor-pointer`, {
                  active: tab === 'xls',
                })}
                onClick={() => setTab('xls')}
                role='tab'
              >
                XLS
              </a>
            </li>
          </ul>
        </div>
        <form className='form'>
          <div className='card-body py-6'>
            {tab === 'products' ? (
              <div className='card-body py-3'>
                <DataTable
                  data={products}
                  table={table}
                  PTotal={PTotal}
                  pageChange={onChangePage}
                  PID={PID}
                  loading={loading}
                />
              </div>
            ) : null}
            {tab === 'xls' ? <ExportToExcel data={products} fileName='myDataa' /> : null}
          </div>
        </form>
      </div>
    </>
  )
}

export {OrderMore}
