import React, {FC, useState} from 'react'
import {isNullOrEmpty} from '../../../../services/Utils'
import {
  NewsAdd,
  NewsUpdate,
  PrivilegeAdd,
  PrivilegeGroupAdd,
  PrivilegeGroupUpdate,
  UpdateMPriv,
} from '../../../../services/main'
import {toast} from 'react-toastify'
import {
  NewsAddReq,
  NewsUpdateReq,
  PrivilegeAddReq,
  PrivilegeGroupAddReq,
  magicnum,
} from '../../../../services/Request'
interface IProps {
  t: any
  item: any
  onAddSuccess: any
}
const PrivCreate: FC<IProps> = ({t, item, onAddSuccess}) => {
  const [PrivGroupID, setPrivGroupID] = useState(isNullOrEmpty(item) ? '' : item.PrivGroupID)
  const [PrivGroupName, setPrivGroupName] = useState(isNullOrEmpty(item) ? '' : item.PrivGroupName)
  const [IsUserGroup, setIsUserGroup] = useState(isNullOrEmpty(item) ? '' : item.IsUserGroup)
  const [count, setCount] = useState(0)
  const [loading, setLaoding] = useState(false)
  const [validate, setValidate] = useState<any>([])

  interface PrivilegeGroup {
    isUserGroup: boolean
    privGroupName: string
    privGroupId: number
  }

  interface PrivilegeGroupAddReq {
    mPriv_Group: PrivilegeGroup
  }

  interface ValidateResponse {
    retType: number
  }

  const save = () => {
    const res: ValidateResponse = validateFunc()
    if (res.retType === 0) {
      setLaoding(true)

      const reqq: PrivilegeGroupAddReq = {
        mPriv_Group: {
          isUserGroup: IsUserGroup,
          privGroupName: PrivGroupName,
          privGroupId: PrivGroupID,
        },
      }

      PrivilegeGroupAdd(reqq, onSuccess, onFailed, 'POST')
    }
  }

  const onSuccess = () => {
    setLaoding(false)
    onAddSuccess(t('common:successCreate'))
  }

  const onFailed = (err: string) => {
    setLaoding(false)
    return toast.error(err)
  }

  const update = () => {
    var res = validateFunc()
    if (res.retType === 0) {
      setLaoding(true)
      const reqq: PrivilegeGroupAddReq = {
        mPriv_Group: {
          isUserGroup: IsUserGroup,
          privGroupName: PrivGroupName,
          privGroupId: PrivGroupID,
        },
      }
      PrivilegeGroupUpdate(reqq, onUpdateSuccess, onFailed, 'PUT')
    }
  }

  const onUpdateSuccess = () => {
    setLaoding(false)
    onAddSuccess(t('common:successUpdate'))
  }

  const validateFunc = () => {
    var res = {retType: 0}

    if (isNullOrEmpty(IsUserGroup)) {
      res.retType = 1
      validate['IsUserGroup'] = true
    }

    if (isNullOrEmpty(PrivGroupName)) {
      res.retType = 1
      validate['PrivGroupName'] = true
    }

    if (isNullOrEmpty(IsUserGroup)) {
      res.retType = 1
      validate['IsUserGroup'] = true
    }

    setValidate(validate)
    setCount(count + 1)
    return res
  }

  const validateChange = (id: string, e: any) => {
    var val = ''
    e.target === undefined ? (val = e) : (val = e.target.value)
    if (val !== '') {
      validate[id] = false
    } else {
      validate[id] = true
    }
    setValidate(validate)
    setCount(count + 1)
  }

  return (
    <div>
      <div className='scroll-x mx-5 mx-xl-10 pt-10 pb-10 w-500px'>
        <span style={{visibility: 'hidden'}}>{count}</span>
        <div className='text-center mb-13'>
          <h1 className='mb-3'>{t('common:privgroup.add')}</h1>
        </div>
        <div className='mb-8'>
          <input
            className='form-control form-control-solid'
            placeholder={t('common:privgroup.PrivGroupID')}
            onChange={(e) => {
              setPrivGroupID(e.target.value)
              validateChange('PrivGroupID', e)
            }}
            disabled={!isNullOrEmpty(item)}
            value={PrivGroupID}
          ></input>
          {validate['PrivGroupID'] ? (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>{t('common:validInsert')}</div>
            </div>
          ) : null}
        </div>

        <div className='mb-8'>
          <input
            className='form-control form-control-solid'
            placeholder={t('common:privgroup.PrivGroupName')}
            onChange={(e) => {
              setPrivGroupName(e.target.value)
              validateChange('PrivGroupName', e)
            }}
            value={PrivGroupName}
          ></input>
          {validate['PrivGroupName'] ? (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>{t('common:validInsert')}</div>
            </div>
          ) : null}
        </div>

        <div className='mb-8'>
          <select
            name='status'
            data-control='select2'
            data-hide-search='true'
            value={IsUserGroup}
            className='form-select form-select-white form-select-sm'
            onChange={(e) => {
              setIsUserGroup(e.target.value)
              validateChange('IsUserGroup', e)
            }}
          >
            <option value=''>{t('common:employee.selectStatus')}</option>
            <option value='Y'>Y</option>
            <option value='N'>N</option>
          </select>
          {validate['IsUserGroup'] ? (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>{t('common:validSelect')}</div>
            </div>
          ) : null}
        </div>

        <div className='pt-10' style={{textAlign: 'right'}}>
          <button
            className='btn btn-primary align-self-center'
            onClick={isNullOrEmpty(item) ? save : update}
            disabled={loading}
          >
            {loading ? t('common:loading') : t('common:save')}
          </button>
        </div>
      </div>
    </div>
  )
}

export {PrivCreate}
