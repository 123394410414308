import i18next from "i18next";
import common_en from "./locales/en/common.json";
import main_en from "./locales/en/main.json";
import { isNullOrEmpty } from "./Utils";

i18next.init({
  interpolation: { escapeValue: false },
  lng: !isNullOrEmpty(localStorage.getItem("lang")) ? localStorage.getItem("lang") : "en",
  resources: {
    
    en: {
      common: common_en,
      main: main_en,
    },
  },
});
