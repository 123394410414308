import {useEffect, useState} from 'react'
import {
  magicnum,
  ProductTatlagaPriceUpdateReq,
  ProductTatlagaPriceAddReq,
  ProductTatlagaPriceListReq,
  ProductTatlagaPriceDeleteOneReq,
  ProductSambarPriceListReq,
  ProductSambarMaterialHemjeeListReq,
  ProductSambarMaterialHemjeeAddReq,
  ProductSambarMaterialHemjeeDeleteReq,
} from '../../../../services/Request'
import {
  ProductTatlagaPriceUpdate,
  ProductTatlagaPriceAdd,
  ProductTatlagaPriceList,
  ProductTatlagaPriceDeleteOne,
  ProductSambarPriceList,
  ProductSambarMaterialHemjeeList,
  ProductSambarMaterialHemjeeAdd,
  ProductSambarMaterialHemjeeDelete,
} from '../../../../services/main'
import {toast} from 'react-toastify'
import 'react-confirm-alert/src/react-confirm-alert.css'
import DataTable from '../../../modules/CustomBootsrapTable/CustomBootsrapTable'
import {KTIcon} from '../../../../_metronic/helpers'
import {isNullOrEmpty, moneyFormat} from '../../../../services/Utils'
import {confirmAlert} from 'react-confirm-alert'

const SambarUneNemeh = ({prod, t}) => {
  const [hemjee_id, setHemjee_id] = useState('')
  const [material_id, setMaterial_id] = useState('')
  const [loading, setloading] = useState(false)
  const [data, setdata] = useState([])
  const [allPriceData, setAllPriceData] = useState([])
  const [validate, setValidate] = useState([])
  const [sizePerPage, setSizePerPage] = useState('')
  const [PID, setPID] = useState('')
  const [PTotal, setPTotal] = useState('')

  useEffect(() => {
    if (!isNullOrEmpty(prod)) {
      getPrice()
    }
  }, [prod, hemjee_id, material_id])

  useEffect(() => {
    if (!isNullOrEmpty(prod)) {
      getAllPrice()
    }
  }, [prod])

  const getPrice = () => {
    if (loading) {
      setloading(true)
    }
    var req = ProductSambarMaterialHemjeeListReq
    req.filter.sambar_id = prod?.id
    req.filter.hemjee_id = hemjee_id
    req.filter.material_id = material_id
    ProductSambarMaterialHemjeeList(req, onListSuccess, onFailed, 'POST')
  }

  const onListSuccess = (response) => {
    setloading(false)
    setdata(response.data)
  }

  const onFailed = (error) => {
    setloading(false)
  }

  const getAllPrice = () => {
    var req = ProductSambarPriceListReq
    req.filter.torol = prod.torol
    ProductSambarPriceList(req, onAllListSuccess, onFailed, 'POST')
  }

  const onAllListSuccess = (response) => {
    setAllPriceData(response.data)
  }
  const getHemjeeMaterialPrice = (hemjee_id, material_id) => {
    var une = ''
    allPriceData.map((item) => {
      if (item.hemjee_id == hemjee_id && item.material_id == material_id) {
        une = item.price
      }
    })
    return isNullOrEmpty(une) ? 'Тохирох үнэ байхгүй' : moneyFormat(une, 'MNT')
  }

  const save = () => {
    var res = validateFunc()
    if (res.retType === 0) {
      var req = ProductSambarMaterialHemjeeAddReq
      req.sambar_id = prod.id
      req.hemjee_id = hemjee_id
      req.material_id = material_id
      ProductSambarMaterialHemjeeAdd(req, onSaveSuccess, onFailed, 'POST')
    }
  }

  const onSaveSuccess = (response) => {
    setHemjee_id('')
    setMaterial_id('')
    getPrice()
    return toast.success(t('common:SuccessSave'))
  }

  const validateFunc = () => {
    var res = {retType: 0}

    if (isNullOrEmpty(hemjee_id)) {
      res.retType = 1
      validate['hemjee_id'] = true
    }

    if (isNullOrEmpty(material_id)) {
      res.retType = 1
      validate['material_id'] = true
    }

    setValidate(validate)
    return res
  }

  const submit = (row, type) => {
    confirmAlert({
      customUI: ({onClose}) => {
        return (
          <div className='custom-confirm-ui'>
            <h1>{row.title}</h1>
            <p>
              {' '}
              {type == 'deleteMain' ? t('common:confirmDelete') : t('common:confirmChangeStatus')}
            </p>
            <div className='buttons'>
              <button className='btn btn-active-light btn-color-muted' onClick={onClose}>
                {t('common:no')}
              </button>
              <button
                className='btn btn-primary align-self-center'
                onClick={() => {
                  deleteFunc(row)
                  onClose()
                }}
              >
                {t('common:yes')}
              </button>
            </div>
          </div>
        )
      },
    })
  }

  const deleteFunc = (row) => {
    var req = ProductSambarMaterialHemjeeDeleteReq
    req.id = row.id
    ProductSambarMaterialHemjeeDelete(req, onDeleteSuccess, onFailed, 'POST')
  }

  const onDeleteSuccess = () => {
    getPrice()
    return toast.error(t('common:SuccessDelete'))
  }
  const onChangePage = (pid) => {
    if (pid >= 1) {
      setPID(pid)
      setloading(true)
    }
  }
  const sizeRowFormat = (cell, row) => {
    return (
      <div style={{textAlign: 'center'}}>
        <span>{t(`common:magicnum.ProductSambarHemjee.${cell}`)}</span>
      </div>
    )
  }
  const materialRowFormat = (cell, row) => {
    return (
      <div style={{textAlign: 'center'}}>
        <span>{t(`common:magicnum.ProductSambarMaterial.${cell}`)}</span>
      </div>
    )
  }
  const defaultRowFormat = (cell, row) => {
    return (
      <div style={{textAlign: 'center'}}>
        <span>{cell}</span>
      </div>
    )
  }
  const priceRowFormat = (cell, row) => {
    return (
      <div style={{textAlign: 'center'}}>
        <span>{getHemjeeMaterialPrice(row.hemjee_id, row.material_id)}</span>
      </div>
    )
  }
  const actionRowFormat = (cell, row) => {
    return (
      <div className='text-center' style={{minWidth: 215}}>
        <div
          title='Delete'
          className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
          onClick={() => submit(row, 'deleteMain')}
        >
          <KTIcon iconName='trash' className='fs-3' />
        </div>
      </div>
    )
  }

  var table = {
    sizePerPage: sizePerPage,
    headerKeyColumn: {
      title: 'id',
      dataField: 'PrivNo',
      dataSort: true,
      dataFormat: defaultRowFormat,
      hidden: true,
      filter: {
        type: 'TextFilter',
        placeholder: '...',
      },
    },
    headerOtherColumn: [
      {
        title: 'Хэмжээ',
        dataField: 'hemjee_id',
        dataSort: false,
        dataFormat: sizeRowFormat,
        hidden: false,
        filter: {
          type: 'TextFilter',
          placeholder: '...',
        },
      },
      {
        title: 'Материал',
        dataField: 'material_id',
        dataSort: false,
        dataFormat: materialRowFormat,
        hidden: false,
        filter: {
          type: 'TextFilter',
          placeholder: '...',
        },
      },
      {
        title: 'Үнэ',
        dataField: 'price',
        dataSort: false,
        dataFormat: priceRowFormat,
        hidden: false,
        filter: {
          type: 'TextFilter',
          placeholder: '...',
        },
      },
      {
        title: '',
        dataField: 'id',
        dataSort: false,
        dataFormat: actionRowFormat,
        hidden: false,
        filter: {
          type: 'TextFilter',
          placeholder: '...',
        },
      },
    ],
  }

  return (
    <div style={{display: 'flex', flexDirection: 'column', gap: '12px'}}>
      <div className='row'>
        <div className='col'>
          <select
            onChange={(e) => {
              setHemjee_id(e.target.value)
            }}
            className='form-select form-select-sm form-select-solid'
            name='provider'
            value={hemjee_id}
            id=''
          >
            <option value=''>Хэмжээ</option>
            {magicnum.ProductSambarHemjee.map((el) => {
              return <option value={el}>{t(`common:magicnum.ProductSambarHemjee.${el}`)}</option>
            })}
          </select>
        </div>
        <div className='col'>
          <select
            onChange={(e) => {
              setMaterial_id(e.target.value)
            }}
            className='form-select form-select-sm form-select-solid'
            name='provider'
            value={material_id}
            id=''
          >
            <option value=''>Материал</option>
            {magicnum.ProductSambarMaterial.map((el) => {
              return <option value={el}>{t(`common:magicnum.ProductSambarMaterial.${el}`)}</option>
            })}
          </select>
        </div>
        <div className='col'>
          <button onClick={save} className='btn btn-sm btn-primary'>
            Хадгалах
          </button>
        </div>
      </div>
      <div className='row'>
        {allPriceData.length > 0 ? (
          <DataTable
            data={data}
            table={table}
            PTotal={PTotal}
            pageChange={onChangePage}
            PID={PID}
            loading={loading}
          />
        ) : null}
      </div>
    </div>
  )
}

export default SambarUneNemeh
