import {FC, useEffect, useState} from 'react'
import {KTIcon} from '../../../../_metronic/helpers'
import {PageLink, PageTitle} from '../../../../_metronic/layout/core'
import {
  SizeUnits,
  dateFormat,
  decodeString,
  encodeString,
  getUser,
  isNullOrEmpty,
  moneyFormat,
} from '../../../../services/Utils'
import CustomModal from '../../../modules/CustomModal/modal'

import {toast} from 'react-toastify'
import {confirmAlert} from 'react-confirm-alert'
import 'react-confirm-alert/src/react-confirm-alert.css'
import {useTranslation} from 'react-i18next'
import {Link, useInRouterContext} from 'react-router-dom'
import ModalImage from 'react-modal-image'
import {Base64} from 'js-base64'
import {useSearchParams} from 'react-router-dom'
import DataTable from '../../../modules/CustomBootsrapTable/CustomBootsrapTable'
import {
  BaseRequest,
  magicnum,
  ProductSambarListReq,
  ProductSambarPriceAddReq,
  ProductSambarPriceDeleteOneReq,
  ProductSambarPriceListReq,
  ProductSambarPriceUpdateReq,
  ProductTatlagaListReq,
} from '../../../../services/Request'
import {
  CDN_URL,
  ProductSambarDelete,
  ProductSambarList,
  ProductSambarPriceAdd,
  ProductSambarPriceDeleteOne,
  ProductSambarPriceList,
  ProductSambarPriceUpdate,
  ProductTatlagaDelete,
  ProductTatlagaList,
} from '../../../../services/main'
// import {SambarEdit} from './edit'
const dashboardBreadCrumbs: Array<PageLink> = [
  {
    title: 'Home',
    path: '/dashboard',
    isSeparator: false,
    isActive: false,
  },
]
type Props = {
  type?: string
  uid?: any
}

const SambarPriceList: FC<Props> = (props) => {
  const {t} = useTranslation()
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState([])
  const [item, setItem] = useState<any>(undefined)
  const [PID, setPID] = useState(1)
  const [hemjee_id, sethemjee_id] = useState('')
  const [material_id, setmaterial_id] = useState('')
  const [torol, setTorol] = useState('')
  const [price, setPrice] = useState('')
  const [validate, setValidate] = useState<any>(false)
  const [count, setCount] = useState('')

  useEffect(() => {
    list()
  }, [hemjee_id, material_id, torol])

  useEffect(() => {
    setTorol(item?.torol)
    sethemjee_id(item?.hemjee_id)
    setmaterial_id(item?.material_id)
    setPrice(item?.price)
  }, [item])

  const list = () => {
    if (!loading) {
      setLoading(true)
    }
    var req = ProductSambarPriceListReq
    req.filter.hemjee_id = hemjee_id
    req.filter.material_id = material_id
    req.filter.torol = torol
    ProductSambarPriceList(req, onListSuccess, onFailed, 'POST')
  }

  const onListSuccess = (response: any) => {
    setLoading(false)
    setData(response.data)
  }
  const onFailed = (error: string) => {
    setLoading(false)
    return toast.error(error)
  }
  const save = () => {
    var res = validateFunc()
    if (res.retType === 0) {
      var req = ProductSambarPriceAddReq
      req.torol = torol
      req.material_id = material_id
      req.hemjee_id = hemjee_id
      req.price = price
      ProductSambarPriceAdd(req, onSuccess, onFailed, 'POST')
    }
  }
  const onSuccess = (response: any) => {
    setLoading(false)
    sethemjee_id('')
    setmaterial_id('')
    setTorol('')
    setPrice('')
    list()
    return toast.success(t('common:SuccessSave'))
  }
  const Update = () => {
    var res = validateFunc()
    if (res.retType === 0) {
      var req = ProductSambarPriceUpdateReq
      req.torol = torol
      req.hemjee_id = hemjee_id
      req.material_id = material_id
      req.price = price
      req.id = item?.id
      ProductSambarPriceUpdate(req, onUpdatSuccess, onFailed, 'PUT')
    }
  }

  const onUpdatSuccess = (response: any) => {
    setLoading(false)
    sethemjee_id('')
    setmaterial_id('')
    setTorol('')
    setPrice('')
    list()
    return toast.success(t('common:SuccessEdit'))
  }

  const validateFunc = () => {
    var res = {retType: 0}

    if (isNullOrEmpty(torol)) {
      res.retType = 1
      validate['torol'] = true
    }

    if (isNullOrEmpty(hemjee_id)) {
      res.retType = 1
      validate['hemjee_id'] = true
    }

    if (isNullOrEmpty(material_id)) {
      res.retType = 1
      validate['material_id'] = true
    }

    setValidate(validate)
    setCount(count + 1)
    return res
  }

  const defaultRowFormat = (cell: any, row: any) => {
    return <span className='flex justify-center'>{cell}</span>
  }
  const angilalRowFormat = (cell: any, row: any) => {
    return (
      <span className='flex justify-center'>{t(`common:magicnum.ProductSambarTorol.${cell}`)}</span>
    )
  }
  const hemjeeRowFormat = (cell: any, row: any) => {
    return (
      <span className='flex justify-center'>
        {t(`common:magicnum.ProductSambarHemjee.${cell}`)}
      </span>
    )
  }
  const materialRowFormat = (cell: any, row: any) => {
    return (
      <span className='flex justify-center'>
        {t(`common:magicnum.ProductSambarMaterial.${cell}`)}
      </span>
    )
  }
  const priceRowFormat = (cell: any, row: any) => {
    return <span className='flex justify-center'>{moneyFormat(cell, 'MNT')}</span>
  }
  const actionRowFormat = (cell: string, row: any) => {
    return (
      <div className='justify-center flex' style={{minWidth: '229px'}}>
        <div
          className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
          onClick={() => {
            setItem(row)
          }}
        >
          <KTIcon iconName='pencil' className='fs-3' />
        </div>
        <div
          className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
          onClick={() => submit(row, 'deleteMain')}
        >
          <KTIcon iconName='trash' className='fs-3' />
        </div>
      </div>
    )
  }
  const submit = (row: any, type: string) => {
    confirmAlert({
      customUI: ({onClose}) => {
        return (
          <div className='custom-confirm-ui'>
            <h1>{row.title}</h1>
            <p>
              {' '}
              {type == 'deleteMain' ? t('common:confirmDelete') : t('common:confirmChangeStatus')}
            </p>
            <div className='buttons'>
              <button className='btn btn-active-light btn-color-muted' onClick={onClose}>
                {t('common:no')}
              </button>
              <button
                className='btn btn-primary align-self-center'
                onClick={() => {
                  deleteFunc(row)
                  onClose()
                }}
              >
                {t('common:yes')}
              </button>
            </div>
          </div>
        )
      },
    })
  }
  const deleteFunc = (row: any) => {
    var req = ProductSambarPriceDeleteOneReq
    req.id = row.id
    ProductSambarPriceDeleteOne(req, onDeleteSuccess, onFailed, 'POST')
  }
  const onDeleteSuccess = () => {
    list()
    return toast.success(t('common:successDelete'))
  }

  var table = {
    // sizePerPage: sizePerPage,
    headerKeyColumn: {
      title: 'id',
      dataField: 'PrivNo',
      dataSort: true,
      dataFormat: defaultRowFormat,
      hidden: true,
      filter: {
        type: 'TextFilter',
        placeholder: '...',
      },
    },
    headerOtherColumn: [
      {
        title: 'Ангилал',
        dataField: 'torol',
        dataSort: false,
        dataFormat: angilalRowFormat,
        hidden: false,
        filter: {
          type: 'TextFilter',
          placeholder: '...',
        },
      },
      {
        title: 'Хэмжээ',
        dataField: 'hemjee_id',
        dataSort: false,
        dataFormat: hemjeeRowFormat,
        hidden: false,
        filter: {
          type: 'TextFilter',
          placeholder: '...',
        },
      },
      {
        title: 'Материал',
        dataField: 'material_id',
        dataSort: false,
        dataFormat: materialRowFormat,
        hidden: false,
        filter: {
          type: 'TextFilter',
          placeholder: '...',
        },
      },
      {
        title: 'Үнэ',
        dataField: 'price',
        dataSort: false,
        dataFormat: priceRowFormat,
        hidden: false,
        filter: {
          type: 'TextFilter',
          placeholder: '...',
        },
      },

      {
        title: '',
        dataField: 'id',
        dataSort: false,
        dataFormat: actionRowFormat,
        hidden: false,
        filter: {
          type: 'TextFilter',
          placeholder: '...',
        },
      },
    ],
  }
  return (
    <>
      <PageTitle breadcrumbs={dashboardBreadCrumbs}>Үнэ</PageTitle>

      <div className={`card mb-5`}>
        <div className='card-header border-1' style={{height: '60px'}}>
          <div className=' cursor-pointer filter flex items-center justify-center gap-0'>
            <KTIcon iconName='filter' className='fs-3' />
            <span className='text'>Үнэ хайх</span>
          </div>
        </div>
        <div className='card-body'>
          <div className='row flex items-center filter-wrap'>
            <div className='col'>
              <select
                onChange={(e) => {
                  setTorol(e.target.value)
                }}
                className='form-select form-select-sm form-select-solid'
                name='category'
                value={torol}
              >
                <option value=''>{t('common:select')}</option>
                {magicnum.ProductSambarTorol.map((pitem) => {
                  return (
                    <option value={pitem}>
                      {t(`common:magicnum.ProductSambarTorol.${pitem}`)}
                    </option>
                  )
                })}
              </select>
            </div>
            <div className='col'>
              <select
                onChange={(e) => {
                  sethemjee_id(e.target.value)
                }}
                className='form-select form-select-sm form-select-solid'
                name='sub_category'
                value={hemjee_id}
              >
                <option value=''>{t('common:select')}</option>
                {magicnum.ProductSambarHemjee.map((item, index) => {
                  return (
                    <option value={item} key={index}>
                      {t(`common:magicnum.ProductSambarHemjee.${item}`)}
                    </option>
                  )
                })}
              </select>
            </div>
            <div className='col'>
              <select
                onChange={(e) => {
                  setmaterial_id(e.target.value)
                }}
                className='form-select form-select-sm form-select-solid'
                value={material_id}
              >
                <option value=''>{t('common:select')}</option>
                {magicnum.ProductSambarMaterial.map((item, index) => {
                  return (
                    <option value={item} key={index}>
                      {t(`common:magicnum.ProductSambarMaterial.${item}`)}
                    </option>
                  )
                })}
              </select>
            </div>
            <div className='col'>
              <input
                className='form-control form-control-sm form-control-solid'
                id='name'
                type='text'
                onChange={(e) => setPrice(e.target.value)}
                placeholder='Үнэ'
                value={price}
              />
            </div>
            <div className='col'>
              <div onClick={isNullOrEmpty(item) ? save : Update} className='btn btn-sm btn-primary'>
                {isNullOrEmpty(item) ? "Нэмэх" : "Засах"}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className={`card`}>
        <div className='card-header border-0 pt-5'>
          <h3 className='card-title align-items-start flex-column'>
            <span className='card-label fw-bold fs-3 mb-1'>Самбарын үнэ жагсаалт</span>
            <span className='text-muted mt-1 fw-semibold fs-7'>Нийт {data?.length} үнэ</span>
          </h3>
        </div>
        <div className='card-body py-3'>
          <DataTable
            data={data}
            table={table}
            PID={PID}
            loading={loading}
          />
        </div>
      </div>
    </>
  )
}

export {SambarPriceList}
