import {FC, useEffect, useState} from 'react'
import {KTIcon} from '../../../_metronic/helpers'
import {PageLink, PageTitle} from '../../../_metronic/layout/core'
import {
  LastAndFirstName,
  SizeUnits,
  dateFormat,
  dateTimeFormat,
  decodeString,
  encodeString,
  getUser,
  isNullOrEmpty,
  moneyFormat,
} from '../../../services/Utils'
import CustomModal from '../../modules/CustomModal/modal'

import {toast} from 'react-toastify'
import {confirmAlert} from 'react-confirm-alert'
import 'react-confirm-alert/src/react-confirm-alert.css'
import {useTranslation} from 'react-i18next'
import {Link, useInRouterContext, useParams} from 'react-router-dom'
import ModalImage from 'react-modal-image'
import {Base64} from 'js-base64'
import {useSearchParams} from 'react-router-dom'
import {
  BaseRequest,
  CustomerCheckReq,
  listEmployeeReq,
  magicnum,
  OrderChangeStatusReq,
  OrderListReq,
  ProductSambarListReq,
  ProductTatlagaListReq,
} from '../../../services/Request'
import {
  CDN_URL,
  CustomerCheck,
  listEmployee,
  OrderChangeStatus,
  OrderDelete,
  OrderList,
  ProductSambarDelete,
  ProductSambarList,
  ProductTatlagaDelete,
  ProductTatlagaList,
} from '../../../services/main'
import {SambarEdit} from '../products/sambar/edit'
import DataTable from '../../modules/CustomBootsrapTable/CustomBootsrapTable'
const dashboardBreadCrumbs: Array<PageLink> = [
  {
    title: 'Нүүр',
    path: '/dashboard',
    isSeparator: false,
    isActive: false,
  },
]
type Props = {
  type?: string
  uid?: any
  customerId?: any
  id?: string
}

const OrderLists: FC<Props> = (props) => {
  const {id} = useParams<string>()
  const {t} = useTranslation()
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState([])
  const [dataEmployee, setDataEmployee] = useState<any>([])
  const [item, setItem] = useState<any>(undefined)
  const [PID, setPID] = useState(1)
  const [PTotal, setPTotal] = useState(1)
  const [sizePerPage, setSizePerPage] = useState(10)
  const [showModal, updateShowModal] = useState(false)
  const [addShowModal, setAddShowModal] = useState(false)
  const [showFilter, setShowFilter] = useState(false)
  const [statusModal, setStatusModal] = useState(false)
  const [mobile, setmobile] = useState('')
  const [rd, setrd] = useState('')
  const [orderId, setorderId] = useState('')
  const [huviarlah_hun_search, sethuviarlah_hun_search] = useState('')
  const [tolov, setTolov] = useState('')
  const [orderStatus, setOrderStatus] = useState(item?.status)
  useEffect(() => {
    list()
    employeeList()
  }, [PID])

  const list = () => {
    if (!loading) {
      setLoading(true)
    }
    var req = OrderListReq
    req.pid = PID
    req.pSize = sizePerPage
    req.filter.mobile = mobile
    req.filter.rd = rd
    req.filter.orderid = orderId
    req.filter.status = isNullOrEmpty(id) ? tolov : id as string
    req.filter.hariutssan_hun = huviarlah_hun_search
    req.filter.customer_id = props?.customerId
    OrderList(req, onSuccess, onFailed, 'POST')
  }
  const onSuccess = (response: any) => {
    setLoading(false)
    setData(response.data)
    setPTotal(response.pagination.ptotal)
  }
  const onFailed = (error: string) => {
    setLoading(false)
    return toast.error(error)
  }

  const employeeList = () => {
    var req = listEmployeeReq
    req.pid = 1
    req.pSize = 1000
    listEmployee(req, onEmployeeListSuccess, onFailed, 'POST')
  }
  const onEmployeeListSuccess = (response: any) => {
    setDataEmployee(response.mEmployees)
  }

  const onChangePage = (pid: any) => {
    if (pid >= 1) {
      setPID(pid)
      setLoading(true)
    }
  }
  const defaultRowFormat = (cell: any, row: any) => {
    return <span className='flex justify-center'>{cell}</span>
  }
  const tololtRowFormat = (cell: any, row: any) => {
    return (
      <div className={cell == 'invoice' ? 'text_yellow' : 'text_green'}>
        {cell == 'invoice' ? 'Нэхэмжлэл' : 'Qpay'}
      </div>
    )
  }
  const moneyRowFormat = (cell: any, row: any) => {
    return <span className='flex justify-center'>{moneyFormat(cell, 'MNT')}</span>
  }
  const linkRowFormat = (cell: any, row: any) => {
    return (
      <div className='flex justify-center'>
        <Link to={`/order/${cell}`}>NO-{cell}</Link>
      </div>
    )
  }
  const huviarlahHunRowFormat = (cell: any) => {
    return dataEmployee.length > 0 ? dataEmployee.filter((el: any) => el.id == cell) : null
  }
  const dateRowFormat = (cell: any, row: any) => {
    return <span className='flex justify-center'>{dateTimeFormat(cell)}</span>
  }
  const statusRowFormat = (cell: any, row: any) => {
    return (
      <div
        onClick={() => {
          setStatusModal(true)
          setItem(row)
        }}
        className={
          cell == 1
            ? 'bg_grey'
            : cell == 2
            ? 'bg_pink'
            : cell == 3
            ? 'bg_blue'
            : cell == 4
            ? 'bg_yellow'
            : cell == 5
            ? 'bg_green'
            : 'bg_red'
        }
      >
        {t(`common:magicnum.OrderStatus.${cell}`)}
      </div>
    )
  }
  const actionRowFormat = (cell: string, row: any) => {
    return (
      <div className='justify-center flex' style={{minWidth: '229px'}}>
        <div
          className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
          onClick={() => submit(row, 'deleteMain')}
        >
          <KTIcon iconName='trash' className='fs-3' />
        </div>
      </div>
    )
  }
  const submit = (row: any, type: string) => {
    confirmAlert({
      customUI: ({onClose}) => {
        return (
          <div className='custom-confirm-ui'>
            <h1>{row.title}</h1>
            <p>
              {' '}
              {type == 'deleteMain' ? t('common:confirmDelete') : t('common:confirmChangeStatus')}
            </p>
            <div className='buttons'>
              <button className='btn btn-active-light btn-color-muted' onClick={onClose}>
                {t('common:no')}
              </button>
              <button
                className='btn btn-primary align-self-center'
                onClick={() => {
                  deleteFunc(row)
                  onClose()
                }}
              >
                {t('common:yes')}
              </button>
            </div>
          </div>
        )
      },
    })
  }
  const deleteFunc = (row: any) => {
    OrderDelete(row.id, BaseRequest, onDeleteSuccess, onFailed, 'DELETE')
  }
  const onDeleteSuccess = () => {
    list()
    return toast.success(t('common:successDelete'))
  }
  const ChangeStatus = () => {
    var req = OrderChangeStatusReq
    req.Order.status = orderStatus
    req.Order.id = item?.id
    req.Order.employeeID = item?.hariutssan_hun
    OrderChangeStatus(req, statusChangeSuccess, onFailed, 'PUT')
  }
  const statusChangeSuccess = (response: any) => {
    setStatusModal(false)
    list()
    return toast.success("Захиалгын төлөв амжилттай солигдлоо.")
  }

  const clearFilter = () => {
    sethuviarlah_hun_search('')
    if (PID > 1) {
      setPID(1)
    } else {
      list()
    }
  }
  const search = () => {
    if (PID > 1) {
      setPID(1)
    } else {
      list()
    }
  }
  var table = {
    sizePerPage: sizePerPage,
    headerKeyColumn: {
      title: 'id',
      dataField: 'PrivNo',
      dataSort: true,
      dataFormat: defaultRowFormat,
      hidden: true,
      filter: {
        type: 'TextFilter',
        placeholder: '...',
      },
    },
    headerOtherColumn: [
      {
        title: 'Захиалгын дугаар',
        dataField: 'id',
        dataSort: false,
        dataFormat: linkRowFormat,
        hidden: false,
        filter: {
          type: 'TextFilter',
          placeholder: '...',
        },
      },
      // {
      //   title: 'Хувиарласан ажилтан',
      //   dataField: 'hariutssan_hun',
      //   dataSort: false,
      //   dataFormat: huviarlahHunRowFormat,
      //   hidden: false,
      //   filter: {
      //     type: 'TextFilter',
      //     placeholder: '...',
      //   },
      // },
      {
        title: 'Мөнгөн дүн',
        dataField: 'total_price',
        dataSort: false,
        dataFormat: moneyRowFormat,
        hidden: false,
        filter: {
          type: 'TextFilter',
          placeholder: '...',
        },
      },
      {
        title: 'Төлбөр',
        dataField: 'payment_type',
        dataSort: false,
        dataFormat: tololtRowFormat,
        hidden: false,
        filter: {
          type: 'TextFilter',
          placeholder: '...',
        },
      },

      {
        title: 'Харилцагчийн РД',
        dataField: 'rd',
        dataSort: false,
        dataFormat: defaultRowFormat,
        hidden: false,
        filter: {
          type: 'TextFilter',
          placeholder: '...',
        },
      },
      {
        title: 'Утасны дугаар',
        dataField: 'mobile',
        dataSort: false,
        dataFormat: defaultRowFormat,
        hidden: false,
        filter: {
          type: 'TextFilter',
          placeholder: '...',
        },
      },
      {
        title: 'Захиалга үүссэн огноо',
        dataField: 'created_at',
        dataSort: false,
        dataFormat: dateRowFormat,
        hidden: false,
        filter: {
          type: 'TextFilter',
          placeholder: '...',
        },
      },
      {
        title: 'Төлөв',
        dataField: 'status',
        dataSort: false,
        dataFormat: statusRowFormat,
        hidden: false,
        filter: {
          type: 'TextFilter',
          placeholder: '...',
        },
      },
      {
        title: '',
        dataField: 'id',
        dataSort: false,
        dataFormat: actionRowFormat,
        hidden: false,
        filter: {
          type: 'TextFilter',
          placeholder: '...',
        },
      },
    ],
  }
  const closeModal = () => {
    updateShowModal(false)
    setAddShowModal(false)
    list()
  }
  return (
    <>
      {showModal ? (
        <CustomModal
          visible={showModal}
          closeModal={() => updateShowModal(false)}
          className='VideoModal'
        >
          <SambarEdit t={t} item={item} type='edit' onAddSuccess={closeModal} />
        </CustomModal>
      ) : null}

      {addShowModal ? (
        <CustomModal
          visible={addShowModal}
          closeModal={() => setAddShowModal(false)}
          className='VideoModal'
        >
          <SambarEdit t={t} item={item} type='add' onAddSuccess={closeModal} />
        </CustomModal>
      ) : null}

      {statusModal ? (
        <CustomModal
          visible={statusModal}
          closeModal={() => setStatusModal(false)}
          className='VideoModal'
        >
          <div
            style={{
              minWidth: '440px',
              padding: '40px',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              gap: '6px',
            }}
          >
            <h1>NO-{item.id} Захиалгын төлөв солих</h1>
            <span>Та энэ үйлдлийг хийхдээ итгэлтэй байна уу?</span>
            <select
              value={orderStatus}
              onChange={(e) => setOrderStatus(e.target.value)}
              className='form-select form-select-sm form-select-solid mt-8'
              name=''
              id=''
            >
              <option value=''>Сонгох</option>
              {magicnum.OrderStatus.map((el) => {
                return <option value={el}>{t(`common:magicnum.OrderStatus.${el}`)}</option>
              })}
            </select>
            <div className='flex mt-6 gap-3'>
              <button
                onClick={() => setStatusModal(false)}
                className='btn btn-sm btn-light-warning'
              >
                Үгүй
              </button>
              <button onClick={ChangeStatus} className='btn btn-sm btn-primary'>
                Тийм
              </button>
            </div>
          </div>
        </CustomModal>
      ) : null}

      {props.type == 'component' ? null : (
        <>
          <PageTitle breadcrumbs={dashboardBreadCrumbs}>Захиалга</PageTitle>

          <div className={`card mb-5`}>
            <div className='card-header border-1' style={{height: '60px'}}>
              <div
                onClick={() => setShowFilter(!showFilter)}
                className=' cursor-pointer filter flex items-center justify-center gap-0'
              >
                <KTIcon iconName='filter' className='fs-3' />
                <span className='text'>{t('common:filter')}</span>
              </div>
              <div className='card-toolbar'>
                <a onClick={() => search()} className='btn btn-sm btn-light-primary'>
                  {t('common:search')}
                </a>
              </div>
            </div>
            {showFilter ? (
              <div className='card-body'>
                <div className='row flex items-center filter-wrap'>
                  <div className='col'>
                    <select
                      onChange={(e) => {
                        sethuviarlah_hun_search(e.target.value)
                      }}
                      className='form-select form-select-sm form-select-solid'
                      name='category'
                      value={huviarlah_hun_search}
                    >
                      <option value=''>Хувиарласан ажилтан</option>
                      {dataEmployee.map((pitem: any) => {
                        return <option value={pitem.id}>{pitem.firstname}</option>
                      })}
                    </select>
                  </div>
                  <div className='col'>
                    <input
                      className='form-control form-control-sm form-control-solid'
                      id='name'
                      type='text'
                      onChange={(e) => setorderId(e.target.value)}
                      placeholder='Захиалгын дугаар'
                      value={orderId}
                    />
                  </div>
                  <div className='col'>
                    <select
                      onChange={(e) => {
                        setTolov(e.target.value)
                      }}
                      className='form-select form-select-sm form-select-solid'
                      name='category'
                      value={tolov}
                    >
                      <option value=''>Төлөв сонгох</option>
                      {magicnum.OrderStatus.map((pitem: any) => {
                        return (
                          <option value={pitem}>{t(`common:magicnum.OrderStatus.${pitem}`)}</option>
                        )
                      })}
                    </select>
                  </div>
                  <div className='col'>
                    <input
                      className='form-control form-control-sm form-control-solid'
                      id='rd'
                      type='text'
                      onChange={(e) => setrd(e.target.value)}
                      placeholder='Харилцагчийн РД'
                      value={rd}
                    />
                  </div>
                  <div className='col'>
                    <input
                      className='form-control form-control-sm form-control-solid'
                      id='rd'
                      type='text'
                      onChange={(e) => setmobile(e.target.value)}
                      placeholder='Гар утасны дугаар'
                      value={mobile}
                    />
                  </div>
                  <div className='col'>
                    <a
                      onClick={() => clearFilter()}
                      className='btn btn-sm btn-icon btn-color-primary btn-active-light-primary'
                    >
                      {t('common:USERS.clear')}
                    </a>
                  </div>
                </div>
              </div>
            ) : null}
          </div>
        </>
      )}

      <div className={`card`}>
        <div className='card-header border-0 pt-5'>
          <h3 className='card-title align-items-start flex-column'>
            <span className='card-label fw-bold fs-3 mb-1'>Захиалгын жагсаалт</span>
            <span className='text-muted mt-1 fw-semibold fs-7'>Нийт {PTotal} захиалга</span>
          </h3>
        </div>
        <div className='card-body py-3'>
          <DataTable
            data={data}
            table={table}
            PTotal={PTotal}
            pageChange={onChangePage}
            PID={PID}
            loading={loading}
          />
        </div>
      </div>
    </>
  )
}

export {OrderLists}
