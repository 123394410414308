import { getRealData, isNullOrEmpty, IsPrivilege } from "./Utils";
import config from "./config.json";
// import { io } from "socket.io-client";
var CryptoJS = require("crypto-js");
var varBASE_URL = "";
var varCDN_URL = "";
var debugMode = config.debug;
if (debugMode === "local") {
    varCDN_URL = config.CDN_URL;
    varCDN_URL = config.RealCDN_URL;
    varBASE_URL = config.CDN_URL + '/';
} else if (debugMode === "real") {
    varCDN_URL = config.RealCDN_URL;
    varBASE_URL = config.RealCDN_URL + '/';
} else {
    varCDN_URL = config.TestCDN_URL;
    varBASE_URL = config.TestCDN_URL + '/';
}
// export const socket = io(varBASE_URL);
export const BASE_URL = varBASE_URL;
export const CDN_URL = varCDN_URL;
export function setPageTitle(text) {
  document.title = text + " | " + " MKL";
}

function isPriv(url, method, failCallback) {
  var isprivNo = false; //false bn shuu
  var realprivNoArray = url.split("/"); //api/Library/LibraryList
  var realprivNo = ""; //Employee-Post

  if (realprivNoArray[0] === "api") {
    for (var i = 0; i <= realprivNoArray.length - 1; i++) {
      if (realprivNoArray[i] !== "api" && realprivNoArray[i] !== "" && i <= 2) {
        realprivNo += realprivNoArray[i];
        realprivNo += "-";
      }
    }
    realprivNo = realprivNo + method;
  const data1 = localStorage.getItem('data1')

    var userPriv = !isNullOrEmpty(getRealData(data1))
      ? JSON.parse(getRealData(data1))
      : []; //localstroge iin data1 ni tuhain hereglegchiin privNo nuud yum
      userPriv.map((i, index) => {
      if (i.PrivNo === realprivNo) {
        isprivNo = true;
      }
    });
  } else if (realprivNoArray[0] === "publicapi") {
    isprivNo = true;
  }

  return isprivNo;
} //ene code client der erh shalgaj baina ta harj bnu?ok

function baseFetch(
  url,
  request,
  successCallback,
  failCallback,
  rMethod,
  rHeader
) {
  if (isPriv(url, rMethod, failCallback)) {
    var statusCode = "";
    var baseHeader = {
      "x-auth-id": localStorage.getItem("EmployeeID"),
      "x-auth-token": localStorage.getItem("SystemToken"),
      "x-api-key": CryptoJS.AES.encrypt(
        config.keyAPI,
        config.engryptPass
      ).toString(),
      "Content-Type": "application/json",
      lang: "mn",
    };
    var baseHeaderFileUpload = {
      "x-auth-id": localStorage.getItem("EmployeeID"),
      "x-auth-token": localStorage.getItem("SystemToken"),
      "x-api-key": CryptoJS.AES.encrypt(
        config.keyAPI,
        config.engryptPass
      ).toString(),
      lang: "mn",
    };
    fetch(BASE_URL + url, {
      crossDomain: true,
      //mode:'no-cors',
      method: rMethod,
      headers: rHeader == "file" ? baseHeaderFileUpload : baseHeader,
      body:
        rHeader == "file"
          ? request
          : rMethod !== "GET"
          ? JSON.stringify(request)
          : null,
    })
      .then((res) => {
        statusCode = res.status;
        return res.json();
      })
      .then(function (data) {
        if (statusCode === 401) {
          localStorage.clear();
          setTimeout(() => {
            window.location.replace("#/logout");
          });
        } else if (statusCode === 200) {
          TokenHandler(url, data, successCallback);
        } else {
          errorJob(url, data.statusText, failCallback);
        }
      })
      .catch(function (error) {
        errorJob(url, "Алдаа гарсан тул, шинээр нэвтэрнэ үү", failCallback);
        window.location.replace("#/logout");
      });
  } else {
    errorJob(url, "Хандах эрх байхгүй байна.", failCallback);
  }
} //baseFetch gedeg api duuddag yum bgaa js ter ni neg api duudhad iim coed bichigddeg teriig ni bagasgatsan bgaa  TypeZurchilList(req, this.onSuccess, this.onFailed, "POST");

function TokenHandler(url, response, successCallback) {
  var bytes = CryptoJS.AES.decrypt(response, config.engryptPass);
  var decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
  successCallback(decryptedData);
}

function errorJob(url, error, failCallback) {
  failCallback(error);
  //return toast.error(error);
}

function errorFetch(url, request) {
  var baseHeader = {
    Authorization: "Bearer " + getRealData("SystemToken"),
    "x-api-key": config.keyAPI,
    "Content-Type": "application/json",
    Language: localStorage.getItem("Lang"),
  };
  fetch(BASE_URL + url, {
    method: "POST",
    headers: baseHeader,
    body: JSON.stringify(request),
  }).catch((error) => console.error("Error:", error));
}
//#region [ AuthCreate & Verify ]
export function loginGetToken(request, successCallback, failCallback, method) {
  baseFetch(
    "publicapi/auth/signin",
    request,
    successCallback,
    failCallback,
    method
  );
  return "";
}

export function signOut(request, successCallback, failCallback, method) {
  baseFetch(
    "publicapi/auth/signout",
    request,
    successCallback,
    failCallback,
    method
  );
  return "";
}
//#endregion

//#region [ Ажилчдын жагсаалт ]
export function listEmployee(request, successCallback, failCallback, method) {
  baseFetch(
    "api/employee/getallemployee",
    request,
    successCallback,
    failCallback,
    method
  );
  return "";
}

export function EmployeeChangeInfo(
  request,
  successCallback,
  failCallback,
  method,
  rHeader
) {
  baseFetch(
    "api/employee/update",
    request,
    successCallback,
    failCallback,
    method,
    rHeader
  );
  return "";
}

export function EmployeeChangePhoto(
  request,
  successCallback,
  failCallback,
  method,
  rHeader
) {
  baseFetch(
    "api/employee/changephoto",
    request,
    successCallback,
    failCallback,
    method,
    rHeader
  );
  return "";
}

export function EmployeeAdd(
  request,
  successCallback,
  failCallback,
  method,
  rHeader
) {
  baseFetch(
    "api/employee/addemployee",
    request,
    successCallback,
    failCallback,
    method,
    rHeader
  );
  return "";
}

export function ChangePass(request, successCallback, failCallback, method) {
  baseFetch(
    "api/employee/changepassword",
    request,
    successCallback,
    failCallback,
    method
  );
  return "";
}

export function GetEmployee(
  id,
  request,
  successCallback,
  failCallback,
  method
) {
  baseFetch(
    "api/employee/getemployee/" + id,
    request,
    successCallback,
    failCallback,
    method
  );
  return "";
}

export function EmployeeDelete(
  employeeID,
  request,
  successCallback,
  failCallback,
  method
) {
  baseFetch(
    "api/employee/deleteemployee/" + employeeID,
    request,
    successCallback,
    failCallback,
    method
  );
  return "";
}

//#endregion

//#region [Privilege]
// EmployeeList =>request(GetEmployeeByIdReq)
export function UserPrivList(
  id,
  request,
  successCallback,
  failCallback,
  method
) {
  baseFetch(
    "api/priv/userprivlist/" + id,
    request,
    successCallback,
    failCallback,
    method
  );
  return "";
}

//PrivGroupList List =>request(BaseRequest)
export function PrivilegeGroupList(
  request,
  successCallback,
  failCallback,
  method
) {
  baseFetch(
    "api/privgroup/listmpriv_group",
    request,
    successCallback,
    failCallback,
    method
  );
  return "";
}

//PrivGroupAdd Add =>request(PrivilegeGroupAddReq)
export function PrivilegeGroupAdd(
  request,
  successCallback,
  failCallback,
  method
) {
  baseFetch(
    "api/privgroup/addmpriv_group",
    request,
    successCallback,
    failCallback,
    method
  );
  return "";
}

//PrivGroupDelete Delete =>request(PrivilegeGroupDeleteReq)
export function PrivilegeGroupDelete(
  id,
  request,
  successCallback,
  failCallback,
  method
) {
  baseFetch(
    "api/privgroup/deletempriv_group/" + id,
    request,
    successCallback,
    failCallback,
    method
  );
  return "";
}

//PrivList Delete =>request(PrivilegeGroupAddReq)
export function PrivilegeGroupGet(
  id,
  request,
  successCallback,
  failCallback,
  method
) {
  baseFetch(
    "api/privgroup/getmpriv_group/" + id,
    request,
    successCallback,
    failCallback,
    method
  );
  return "";
}

//PrivList Delete =>request(PrivilegeGroupAddReq)
export function PrivilegeGroupUpdate(
  request,
  successCallback,
  failCallback,
  method
) {
  baseFetch(
    "api/privgroup/updatempriv_group",
    request,
    successCallback,
    failCallback,
    method
  );
  return "";
}

//PrivList List =>request(BaseRequest)
export function PrivilegeList(request, successCallback, failCallback, method) {
  baseFetch(
    "api/priv/listpriv",
    request,
    successCallback,
    failCallback,
    method
  );
  return "";
}

//PrivList Add =>request(PrivilegeAddReq)
export function PrivilegeAdd(request, successCallback, failCallback, method) {
  baseFetch(
    "api/priv/addmpriv",
    request,
    successCallback,
    failCallback,
    method
  );
  return "";
}

//PrivList Delete =>request(PrivilegeDeleteReq)
export function PrivilegeDelete(
  id,
  request,
  successCallback,
  failCallback,
  method
) {
  baseFetch(
    "api/priv/deletempriv/" + id,
    request,
    successCallback,
    failCallback,
    method
  );
  return "";
}

//PrivList Delete =>request(PrivilegeUserListReq)
export function GetUSerPrivile(
  id,
  request,
  successCallback,
  failCallback,
  method
) {
  baseFetch(
    "api/priv/getmpriv/" + id,
    request,
    successCallback,
    failCallback,
    method
  );
  return "";
}

//PrivList Delete =>request(PrivilegeUserListReq)
export function UpdateMPriv(request, successCallback, failCallback, method) {
  baseFetch(
    "api/priv/updatempriv",
    request,
    successCallback,
    failCallback,
    method
  );
  return "";
}

//AddMPriv_GroupUser =>request(PrivilegeMP_GroupUserAddReq)
export function AddMPriv_GroupUser(
  request,
  successCallback,
  failCallback,
  method
) {
  baseFetch(
    "api/privgroupuser/addmpriv_groupuser",
    request,
    successCallback,
    failCallback,
    method
  );
  return "";
}

//UpdateMPriv_GroupUser =>request(PrivilegeMP_GroupUserAddReq)
export function UpdateMPriv_GroupUser(
  request,
  successCallback,
  failCallback,
  method
) {
  baseFetch(
    "api/privgroupuser/updatempriv_groupuser",
    request,
    successCallback,
    failCallback,
    method
  );
  return "";
}

//AddMPriv_GroupPriv =>request(AddMPriv_GroupPrivReq)
export function AddMPriv_GroupPriv(
  request,
  successCallback,
  failCallback,
  method
) {
  baseFetch(
    "api/privgrouppriv/addmpriv_grouppriv",
    request,
    successCallback,
    failCallback,
    method
  );
  return "";
}

//GetMPriv_GroupPriv =>request(GetMPriv_GroupPrivReq)
export function GetMPriv_GroupPriv(
  request,
  successCallback,
  failCallback,
  method
) {
  baseFetch(
    "api/privgroup/addmpriv_grouppriv",
    request,
    successCallback,
    failCallback,
    method
  );
  return "";
}

export function GetMPriv_GetAllMPGroupPriv(
  request,
  successCallback,
  failCallback,
  method
) {
  baseFetch(
    "api/privgroup/getallmpriv_grouppriv",
    request,
    successCallback,
    failCallback,
    method
  );
  return "";
}

//ListMPriv_GroupPriv =>request(ListMPriv_GroupPrivReq)
export function ListMPriv_GroupPriv(
  id,
  request,
  successCallback,
  failCallback,
  method
) {
  baseFetch(
    "api/privgrouppriv/listmpriv_grouppriv/" + id,
    request,
    successCallback,
    failCallback,
    method
  );
  return "";
}

//UpdateMPriv_GroupPriv =>request(AddMPriv_GroupPrivReq)
export function UpdateMPriv_GroupPriv(
  request,
  successCallback,
  failCallback,
  method
) {
  baseFetch(
    "api/privgrouppriv/updatempriv_grouppriv",
    request,
    successCallback,
    failCallback,
    method
  );
  return "";
}

//UpdateMPriv_GroupPriv =>request(GetEmployeeByIdReq)
export function ListMPriv_GroupsForUser(
  userkey,
  request,
  successCallback,
  failCallback,
  method
) {
  baseFetch(
    "api/privgroupuser/getmpriv_groupuser/" + userkey,
    request,
    successCallback,
    failCallback,
    method
  );
  return "";
}
//#endregion

//#region [Log]
export function LogAction(
  request,
  successCallback,
  failCallback,
  method,
  rHeader
) {
  baseFetch(
    "api/log/action",
    request,
    successCallback,
    failCallback,
    method,
    rHeader
  );
  return "";
}

export function LogError(
  request,
  successCallback,
  failCallback,
  method,
  rHeader
) {
  baseFetch(
    "api/log/error",
    request,
    successCallback,
    failCallback,
    method,
    rHeader
  );
  return "";
}
//#endregion

//#region [Chat]
export function ChatAdd(
  request,
  successCallback,
  failCallback,
  method,
  rHeader
) {
  baseFetch(
    "api/chat/add",
    request,
    successCallback,
    failCallback,
    method,
    rHeader
  );
  return "";
}
//#endregion

//#region [Burtgel]
export function BurtgelAdd(
  request,
  successCallback,
  failCallback,
  method,
  rHeader
) {
  baseFetch(
    "api/burtgel/add",
    request,
    successCallback,
    failCallback,
    method,
    rHeader
  );
  return "";
}

export function BurtgelUpdate(
  request,
  successCallback,
  failCallback,
  method,
  rHeader
) {
  baseFetch(
    "api/burtgel/update",
    request,
    successCallback,
    failCallback,
    method,
    rHeader
  );
  return "";
}

export function BurtgelList(
  request,
  successCallback,
  failCallback,
  method,
  rHeader
) {
  baseFetch(
    "api/burtgel/list",
    request,
    successCallback,
    failCallback,
    method,
    rHeader
  );
  return "";
}

export function BurtgelDelete(
  id,
  request,
  successCallback,
  failCallback,
  method
) {
  baseFetch(
    "api/burtgel/delete/" + id,
    request,
    successCallback,
    failCallback,
    method
  );
  return "";
}

export function BurtgelGet(id, request, successCallback, failCallback, method) {
  baseFetch(
    "api/burtgel/get/" + id,
    request,
    successCallback,
    failCallback,
    method
  );
  return "";
}
//#endregion

//#region [Dashboard]
export function DashboardToonMedeelel(
  request,
  successCallback,
  failCallback,
  method,
  rHeader
) {
  baseFetch(
    "api/dashboard/toonmedeelel",
    request,
    successCallback,
    failCallback,
    method,
    rHeader
  );
  return "";
}

export function DashboardByUser(
  request,
  successCallback,
  failCallback,
  method,
  rHeader
) {
  baseFetch(
    "api/dashboard/byuser",
    request,
    successCallback,
    failCallback,
    method,
    rHeader
  );
  return "";
}

export function DashboardByHoroo(
  request,
  successCallback,
  failCallback,
  method,
  rHeader
) {
  baseFetch(
    "api/dashboard/byhoroo",
    request,
    successCallback,
    failCallback,
    method,
    rHeader
  );
  return "";
}

export function DashboardByStatus(
  request,
  successCallback,
  failCallback,
  method,
  rHeader
) {
  baseFetch(
    "api/dashboard/status",
    request,
    successCallback,
    failCallback,
    method,
    rHeader
  );
  return "";
}
//#endregion

//#region [ProductTatlaga]
export function ProductTatlagaList(
  request,
  successCallback,
  failCallback,
  method,
  rHeader
) {
  baseFetch(
    "api/product_tatlaga/list",
    request,
    successCallback,
    failCallback,
    method,
    rHeader
  );
  return "";
}

export function ProductTatlagaAdd(
  request,
  successCallback,
  failCallback,
  method,
  rHeader
) {
  baseFetch(
    "api/product_tatlaga/add",
    request,
    successCallback,
    failCallback,
    method,
    rHeader
  );
  return "";
}

export function ProductTatlagaUpdate(
  request,
  successCallback,
  failCallback,
  method,
  rHeader
) {
  baseFetch(
    "api/product_tatlaga/update",
    request,
    successCallback,
    failCallback,
    method,
    rHeader
  );
  return "";
}

export function ProductTatlagaUpdateTolov(
  request,
  successCallback,
  failCallback,
  method,
  rHeader
) {
  baseFetch(
    "api/product_tatlaga/updatetolov",
    request,
    successCallback,
    failCallback,
    method,
    rHeader
  );
  return "";
}

export function ProductTatlagaGet(
  id,
  request,
  successCallback,
  failCallback,
  method
) {
  baseFetch(
    "api/product_tatlaga/get/" + id,
    request,
    successCallback,
    failCallback,
    method
  );
  return "";
}

export function ProductTatlagaDelete(
  id,
  request,
  successCallback,
  failCallback,
  method
) {
  baseFetch(
    "api/product_tatlaga/delete/" + id,
    request,
    successCallback,
    failCallback,
    method
  );
  return "";
}

export function ProductTatlagaChangeImg(
  request,
  successCallback,
  failCallback,
  method,
  rHeader
) {
  baseFetch(
    "api/product_tatlaga/changeImage",
    request,
    successCallback,
    failCallback,
    method,
    rHeader
  );
  return "";
}

export function ProductTatlagaUpdateuldegdel(
  request,
  successCallback,
  failCallback,
  method,
  rHeader
) {
  baseFetch(
    "api/product_tatlaga/updateuldegdel",
    request,
    successCallback,
    failCallback,
    method,
    rHeader
  );
  return "";
}
//#endregion

//#region [ProductOndor]
export function ProductOndorList(
  request,
  successCallback,
  failCallback,
  method,
  rHeader
) {
  baseFetch(
    "api/product_ondor/list",
    request,
    successCallback,
    failCallback,
    method,
    rHeader
  );
  return "";
}

export function ProductOndorAdd(
  request,
  successCallback,
  failCallback,
  method,
  rHeader
) {
  baseFetch(
    "api/product_ondor/add",
    request,
    successCallback,
    failCallback,
    method,
    rHeader
  );
  return "";
}

export function ProductOndorUpdate(
  request,
  successCallback,
  failCallback,
  method,
  rHeader
) {
  baseFetch(
    "api/product_ondor/update",
    request,
    successCallback,
    failCallback,
    method,
    rHeader
  );
  return "";
}

export function ProductOndorUpdateTolov(
  request,
  successCallback,
  failCallback,
  method,
  rHeader
) {
  baseFetch(
    "api/product_ondor/updatetolov",
    request,
    successCallback,
    failCallback,
    method,
    rHeader
  );
  return "";
}

export function ProductOndorGet(
  id,
  request,
  successCallback,
  failCallback,
  method
) {
  baseFetch(
    "api/product_ondor/get/" + id,
    request,
    successCallback,
    failCallback,
    method
  );
  return "";
}

export function ProductOndorDelete(
  id,
  request,
  successCallback,
  failCallback,
  method
) {
  baseFetch(
    "api/product_ondor/delete/" + id,
    request,
    successCallback,
    failCallback,
    method
  );
  return "";
}

export function ProductOndorChangeImg(
  request,
  successCallback,
  failCallback,
  method,
  rHeader
) {
  baseFetch(
    "api/product_ondor/changeImage",
    request,
    successCallback,
    failCallback,
    method,
    rHeader
  );
  return "";
}

export function ProductOndorUpdateuldegdel(
  request,
  successCallback,
  failCallback,
  method,
  rHeader
) {
  baseFetch(
    "api/product_ondor/updateuldegdel",
    request,
    successCallback,
    failCallback,
    method,
    rHeader
  );
  return "";
}

//#endregion

//#region [ProductTatlagaJin]
export function ProductTatlagaJinList(
  request,
  successCallback,
  failCallback,
  method,
  rHeader
) {
  baseFetch(
    "api/product_tatlaga_jin/list",
    request,
    successCallback,
    failCallback,
    method,
    rHeader
  );
  return "";
}

export function ProductTatlagaJinAdd(
  request,
  successCallback,
  failCallback,
  method,
  rHeader
) {
  baseFetch(
    "api/product_tatlaga_jin/add",
    request,
    successCallback,
    failCallback,
    method,
    rHeader
  );
  return "";
}

export function ProductTatlagaJinUpdate(
  request,
  successCallback,
  failCallback,
  method,
  rHeader
) {
  baseFetch(
    "api/product_tatlaga_jin/update",
    request,
    successCallback,
    failCallback,
    method,
    rHeader
  );
  return "";
}

export function ProductTatlagaJinDelete(
  id,
  request,
  successCallback,
  failCallback,
  method
) {
  baseFetch(
    "api/product_tatlaga_jin/delete/" + id,
    request,
    successCallback,
    failCallback,
    method
  );
  return "";
}
//#endregion

//#region [ProductTatlagaPrice]
export function ProductTatlagaPriceJinList(
  request,
  successCallback,
  failCallback,
  method,
  rHeader
) {
  baseFetch(
    "api/product_tatlaga_price/jinlist",
    request,
    successCallback,
    failCallback,
    method,
    rHeader
  );
  return "";
}

export function ProductTatlagaPriceList(
  request,
  successCallback,
  failCallback,
  method,
  rHeader
) {
  baseFetch(
    "api/product_tatlaga_price/list",
    request,
    successCallback,
    failCallback,
    method,
    rHeader
  );
  return "";
}

export function ProductTatlagaPriceAdd(
  request,
  successCallback,
  failCallback,
  method,
  rHeader
) {
  baseFetch(
    "api/product_tatlaga_price/add",
    request,
    successCallback,
    failCallback,
    method,
    rHeader
  );
  return "";
}

export function ProductTatlagaPriceUpdate(
  request,
  successCallback,
  failCallback,
  method,
  rHeader
) {
  baseFetch(
    "api/product_tatlaga_price/update",
    request,
    successCallback,
    failCallback,
    method,
    rHeader
  );
  return "";
}

export function ProductTatlagaPriceDelete(
  request,
  successCallback,
  failCallback,
  method,
  rHeader
) {
  baseFetch(
    "api/product_tatlaga_price/delete",
    request,
    successCallback,
    failCallback,
    method,
    rHeader
  );
  return "";
}

export function ProductTatlagaPriceDeleteOne(
  request,
  successCallback,
  failCallback,
  method,
  rHeader
) {
  baseFetch(
    "api/product_tatlaga_price/delete_one",
    request,
    successCallback,
    failCallback,
    method,
    rHeader
  );
  return "";
}
//#endregion

//#region [ProductImages]
export function ProductImagesList(
  request,
  successCallback,
  failCallback,
  method,
  rHeader
) {
  baseFetch(
    "api/product_images/list",
    request,
    successCallback,
    failCallback,
    method,
    rHeader
  );
  return "";
}

export function ProductImagesAdd(
  request,
  successCallback,
  failCallback,
  method,
  rHeader
) {
  baseFetch(
    "api/product_images/add",
    request,
    successCallback,
    failCallback,
    method,
    rHeader
  );
  return "";
}

export function ProductImagesDelete(
  id,
  request,
  successCallback,
  failCallback,
  method,
  rHeader
) {
  baseFetch(
    "api/product_images/delete/" + id,
    request,
    successCallback,
    failCallback,
    method,
    rHeader
  );
  return "";
}
//#endregion

//#region [Bagts]
export function BagtsList(
  request,
  successCallback,
  failCallback,
  method,
  rHeader
) {
  baseFetch(
    "api/bagts/list",
    request,
    successCallback,
    failCallback,
    method,
    rHeader
  );
  return "";
}

export function BagtsAdd(
  request,
  successCallback,
  failCallback,
  method,
  rHeader
) {
  baseFetch(
    "api/bagts/add",
    request,
    successCallback,
    failCallback,
    method,
    rHeader
  );
  return "";
}

export function BagtsUpdate(
  request,
  successCallback,
  failCallback,
  method,
  rHeader
) {
  baseFetch(
    "api/bagts/update",
    request,
    successCallback,
    failCallback,
    method,
    rHeader
  );
  return "";
}

export function BagtsUpdateTolov(
  request,
  successCallback,
  failCallback,
  method,
  rHeader
) {
  baseFetch(
    "api/bagts/updatetolov",
    request,
    successCallback,
    failCallback,
    method,
    rHeader
  );
  return "";
}

export function BagtsGet(id, request, successCallback, failCallback, method) {
  baseFetch(
    "api/bagts/get/" + id,
    request,
    successCallback,
    failCallback,
    method
  );
  return "";
}

export function BagtsDelete(
  id,
  request,
  successCallback,
  failCallback,
  method
) {
  baseFetch(
    "api/bagts/delete/" + id,
    request,
    successCallback,
    failCallback,
    method
  );
  return "";
}
//#endregion

//#region [ProductSambar]
export function ProductSambarList(
  request,
  successCallback,
  failCallback,
  method,
  rHeader
) {
  baseFetch(
    "api/product_sambar/list",
    request,
    successCallback,
    failCallback,
    method,
    rHeader
  );
  return "";
}

export function ProductSambarAdd(
  request,
  successCallback,
  failCallback,
  method,
  rHeader
) {
  baseFetch(
    "api/product_sambar/add",
    request,
    successCallback,
    failCallback,
    method,
    rHeader
  );
  return "";
}

export function ProductSambarUpdate(
  request,
  successCallback,
  failCallback,
  method,
  rHeader
) {
  baseFetch(
    "api/product_sambar/update",
    request,
    successCallback,
    failCallback,
    method,
    rHeader
  );
  return "";
}

export function ProductSambarUpdateTolov(
  request,
  successCallback,
  failCallback,
  method,
  rHeader
) {
  baseFetch(
    "api/product_sambar/updatetolov",
    request,
    successCallback,
    failCallback,
    method,
    rHeader
  );
  return "";
}

export function ProductSambarGet(
  id,
  request,
  successCallback,
  failCallback,
  method
) {
  baseFetch(
    "api/product_sambar/get/" + id,
    request,
    successCallback,
    failCallback,
    method
  );
  return "";
}

export function ProductSambarDelete(
  id,
  request,
  successCallback,
  failCallback,
  method
) {
  baseFetch(
    "api/product_sambar/delete/" + id,
    request,
    successCallback,
    failCallback,
    method
  );
  return "";
}

export function ProductSambarChangeImg(
  request,
  successCallback,
  failCallback,
  method,
  rHeader
) {
  baseFetch(
    "api/product_sambar/changeImage",
    request,
    successCallback,
    failCallback,
    method,
    rHeader
  );
  return "";
}

export function ProductSambarUpdateuldegdel(
  request,
  successCallback,
  failCallback,
  method,
  rHeader
) {
  baseFetch(
    "api/product_sambar/updateuldegdel",
    request,
    successCallback,
    failCallback,
    method,
    rHeader
  );
  return "";
}
//#endregion

//#region [ProductSambarPrice]
export function ProductSambarPriceHemjeeList(
  request,
  successCallback,
  failCallback,
  method,
  rHeader
) {
  baseFetch(
    "api/product_sambar_price/hemjeelist",
    request,
    successCallback,
    failCallback,
    method,
    rHeader
  );
  return "";
}

export function ProductSambarPriceList(
  request,
  successCallback,
  failCallback,
  method,
  rHeader
) {
  baseFetch(
    "api/product_sambar_price/list",
    request,
    successCallback,
    failCallback,
    method,
    rHeader
  );
  return "";
}

export function ProductSambarPriceAdd(
  request,
  successCallback,
  failCallback,
  method,
  rHeader
) {
  baseFetch(
    "api/product_sambar_price/add",
    request,
    successCallback,
    failCallback,
    method,
    rHeader
  );
  return "";
}

export function ProductSambarPriceUpdate(
  request,
  successCallback,
  failCallback,
  method,
  rHeader
) {
  baseFetch(
    "api/product_sambar_price/update",
    request,
    successCallback,
    failCallback,
    method,
    rHeader
  );
  return "";
}

export function ProductSambarPriceDeleteOne(
  request,
  successCallback,
  failCallback,
  method,
  rHeader
) {
  baseFetch(
    "api/product_sambar_price/delete_one",
    request,
    successCallback,
    failCallback,
    method,
    rHeader
  );
  return "";
}
//#endregion

//#region [Banner]
export function BannerList(
  request,
  successCallback,
  failCallback,
  method,
  rHeader
) {
  baseFetch(
    "api/banner/list",
    request,
    successCallback,
    failCallback,
    method,
    rHeader
  );
  return "";
}

export function BannerAdd(
  request,
  successCallback,
  failCallback,
  method,
  rHeader
) {
  baseFetch(
    "api/banner/add",
    request,
    successCallback,
    failCallback,
    method,
    rHeader
  );
  return "";
}

export function BannerUpdateTolov(
  request,
  successCallback,
  failCallback,
  method,
  rHeader
) {
  baseFetch(
    "api/banner/updatetolov",
    request,
    successCallback,
    failCallback,
    method,
    rHeader
  );
  return "";
}

export function BannerUpdate(
  request,
  successCallback,
  failCallback,
  method,
  rHeader
) {
  baseFetch(
    "api/banner/update",
    request,
    successCallback,
    failCallback,
    method,
    rHeader
  );
  return "";
}

export function BannerDelete(
  id,
  request,
  successCallback,
  failCallback,
  method
) {
  baseFetch(
    "api/banner/delete/" + id,
    request,
    successCallback,
    failCallback,
    method
  );
  return "";
}
//#endregion

//#region [ProductCran]
export function ProductCranList(
  request,
  successCallback,
  failCallback,
  method,
  rHeader
) {
  baseFetch(
    "api/product_cran/list",
    request,
    successCallback,
    failCallback,
    method,
    rHeader
  );
  return "";
}

export function ProductCranAdd(
  request,
  successCallback,
  failCallback,
  method,
  rHeader
) {
  baseFetch(
    "api/product_cran/add",
    request,
    successCallback,
    failCallback,
    method,
    rHeader
  );
  return "";
}

export function ProductCranUpdate(
  request,
  successCallback,
  failCallback,
  method,
  rHeader
) {
  baseFetch(
    "api/product_cran/update",
    request,
    successCallback,
    failCallback,
    method,
    rHeader
  );
  return "";
}

export function ProductCranGet(
  id,
  request,
  successCallback,
  failCallback,
  method
) {
  baseFetch(
    "api/product_cran/get/" + id,
    request,
    successCallback,
    failCallback,
    method
  );
  return "";
}

export function ProductCranDelete(
  id,
  request,
  successCallback,
  failCallback,
  method
) {
  baseFetch(
    "api/product_cran/delete/" + id,
    request,
    successCallback,
    failCallback,
    method
  );
  return "";
}

export function ProductCranChangeImg(
  request,
  successCallback,
  failCallback,
  method,
  rHeader
) {
  baseFetch(
    "api/product_cran/changeImage",
    request,
    successCallback,
    failCallback,
    method,
    rHeader
  );
  return "";
}

export function ProductCranUpdateuldegdel(
  request,
  successCallback,
  failCallback,
  method,
  rHeader
) {
  baseFetch(
    "api/product_cran/updateuldegdel",
    request,
    successCallback,
    failCallback,
    method,
    rHeader
  );
  return "";
}
//#endregion

//#region [ProductContent]
export function ProductContentAdd(
  request,
  successCallback,
  failCallback,
  method,
  rHeader
) {
  baseFetch(
    "api/product_content/add",
    request,
    successCallback,
    failCallback,
    method,
    rHeader
  );
  return "";
}

export function ProductContentUpdate(
  request,
  successCallback,
  failCallback,
  method,
  rHeader
) {
  baseFetch(
    "api/product_content/update",
    request,
    successCallback,
    failCallback,
    method,
    rHeader
  );
  return "";
}

export function ProductContentGet(
  request,
  successCallback,
  failCallback,
  method,
  rHeader
) {
  baseFetch(
    "api/product_content/get",
    request,
    successCallback,
    failCallback,
    method,
    rHeader
  );
  return "";
}
//#endregion

//#region [ProductSambarMaterialHemjee]
export function ProductSambarMaterialHemjeeAdd(
  request,
  successCallback,
  failCallback,
  method,
  rHeader
) {
  baseFetch(
    "api/sambar_material_hemjee/add",
    request,
    successCallback,
    failCallback,
    method,
    rHeader
  );
  return "";
}

export function ProductSambarMaterialHemjeeList(
  request,
  successCallback,
  failCallback,
  method,
  rHeader
) {
  baseFetch(
    "api/sambar_material_hemjee/list",
    request,
    successCallback,
    failCallback,
    method,
    rHeader
  );
  return "";
}

export function ProductSambarMaterialHemjeeDelete(
  request,
  successCallback,
  failCallback,
  method,
  rHeader
) {
  baseFetch(
    "api/sambar_material_hemjee/delete",
    request,
    successCallback,
    failCallback,
    method,
    rHeader
  );
  return "";
}
//#endregion

//#region [Customer]
export function CustomerList(
  request,
  successCallback,
  failCallback,
  method,
  rHeader
) {
  baseFetch(
    "api/customer/list",
    request,
    successCallback,
    failCallback,
    method,
    rHeader
  );
  return "";
}

export function CustomerCheck(
  request,
  successCallback,
  failCallback,
  method,
  rHeader
) {
  baseFetch(
    "api/customer/check",
    request,
    successCallback,
    failCallback,
    method,
    rHeader
  );
  return "";
}

export function CustomerAdd(
  request,
  successCallback,
  failCallback,
  method,
  rHeader
) {
  baseFetch(
    "api/customer/add",
    request,
    successCallback,
    failCallback,
    method,
    rHeader
  );
  return "";
}

export function CustomerUpdate(
  request,
  successCallback,
  failCallback,
  method,
  rHeader
) {
  baseFetch(
    "api/customer/update",
    request,
    successCallback,
    failCallback,
    method,
    rHeader
  );
  return "";
}

export function CustomerChangePass(
  request,
  successCallback,
  failCallback,
  method,
  rHeader
) {
  baseFetch(
    "api/customer/changepassword",
    request,
    successCallback,
    failCallback,
    method,
    rHeader
  );
  return "";
}

export function CustomerGet(id, request, successCallback, failCallback, method) {
  baseFetch(
    "api/customer/get/" + id,
    request,
    successCallback,
    failCallback,
    method
  );
  return "";
}

export function CustomerDelete(
  id,
  request,
  successCallback,
  failCallback,
  method
) {
  baseFetch(
    "api/customer/delete/" + id,
    request,
    successCallback,
    failCallback,
    method
  );
  return "";
}
//#endregion

//#region [Order]
export function OrderList(
  request,
  successCallback,
  failCallback,
  method,
  rHeader
) {
  baseFetch(
    "api/order/list",
    request,
    successCallback,
    failCallback,
    method,
    rHeader
  );
  return "";
}

export function OrderChangeStatus(
  request,
  successCallback,
  failCallback,
  method,
  rHeader
) {
  baseFetch(
    "api/order/changestatus",
    request,
    successCallback,
    failCallback,
    method,
    rHeader
  );
  return "";
}

export function OrderAdd(
  request,
  successCallback,
  failCallback,
  method,
  rHeader
) {
  baseFetch(
    "api/order/add",
    request,
    successCallback,
    failCallback,
    method,
    rHeader
  );
  return "";
}

export function OrderGet(
  id,
  request,
  successCallback,
  failCallback,
  method
) {
  baseFetch(
    "api/order/get/" + id,
    request,
    successCallback,
    failCallback,
    method
  );
  return "";
}

export function OrderDelete(
  id,
  request,
  successCallback,
  failCallback,
  method
) {
  baseFetch(
    "api/order/delete/" + id,
    request,
    successCallback,
    failCallback,
    method
  );
  return "";
}

//#endregion

//#region [Page]
export function PageList(
  request,
  successCallback,
  failCallback,
  method,
  rHeader
) {
  baseFetch(
    "api/huudas/list",
    request,
    successCallback,
    failCallback,
    method,
    rHeader
  );
  return "";
}

export function PageAdd(
  request,
  successCallback,
  failCallback,
  method,
  rHeader
) {
  baseFetch(
    "api/huudas/add",
    request,
    successCallback,
    failCallback,
    method,
    rHeader
  );
  return "";
}

export function PageUpdate(
  request,
  successCallback,
  failCallback,
  method,
  rHeader
) {
  baseFetch(
    "api/huudas/update",
    request,
    successCallback,
    failCallback,
    method,
    rHeader
  );
  return "";
}

export function PageGet(
  id,
  request,
  successCallback,
  failCallback,
  method
) {
  baseFetch(
    "api/huudas/get/" + id,
    request,
    successCallback,
    failCallback,
    method
  );
  return "";
}

export function PageDelete(
  id,
  request,
  successCallback,
  failCallback,
  method
) {
  baseFetch(
    "api/huudas/delete/" + id,
    request,
    successCallback,
    failCallback,
    method
  );
  return "";
}

//#endregion

//#region [News]
export function NewsList(
  request,
  successCallback,
  failCallback,
  method,
  rHeader
) {
  baseFetch(
    "api/news/list",
    request,
    successCallback,
    failCallback,
    method,
    rHeader
  );
  return "";
}

export function NewsAdd(
  request,
  successCallback,
  failCallback,
  method,
  rHeader
) {
  baseFetch(
    "api/news/add",
    request,
    successCallback,
    failCallback,
    method,
    rHeader
  );
  return "";
}

export function NewsUpdate(
  request,
  successCallback,
  failCallback,
  method,
  rHeader
) {
  baseFetch(
    "api/news/update",
    request,
    successCallback,
    failCallback,
    method,
    rHeader
  );
  return "";
}

export function NewsGet(
  id,
  request,
  successCallback,
  failCallback,
  method
) {
  baseFetch(
    "api/news/get/" + id,
    request,
    successCallback,
    failCallback,
    method
  );
  return "";
}

export function NewsDelete(
  id,
  request,
  successCallback,
  failCallback,
  method
) {
  baseFetch(
    "api/news/delete/" + id,
    request,
    successCallback,
    failCallback,
    method
  );
  return "";
}
//#endregion

//#region [Cran Huselt]
export function CranOrderAdd(request, successCallback, failCallback, method) {
  baseFetch(
    "api/cran_zahialga/add",
    request,
    successCallback,
    failCallback,
    method
  );
  return "";
}

export function CranOrderList(request, successCallback, failCallback, method) {
  baseFetch(
    "api/cran_zahialga/list",
    request,
    successCallback,
    failCallback,
    method
  );
  return "";
}

export function CranOrderDelete(
  id,
  request,
  successCallback,
  failCallback,
  method
) {
  baseFetch(
    "api/cran_zahialga/delete/" + id,
    request,
    successCallback,
    failCallback,
    method
  );
  return "";
}

export function CranOrderChangeStatus(
  request,
  successCallback,
  failCallback,
  method,
  rHeader
) {
  baseFetch(
    "api/cran_zahialga/changestatus",
    request,
    successCallback,
    failCallback,
    method,
    rHeader
  );
  return "";
}
//#endregion